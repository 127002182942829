@import "../scss/mmt/mobilemedtek_colors";
@import "../scss/z-index.scss";

.toasts {
    $width: 300px;
    $padding: 15px;
    $icon-size: 20px;

    position: fixed;
    right: 20px;
    width: $width;
    z-index: $toast-z-index;
    margin: 0;

    .message {
        font-size: 15px;
        background: #fff;
        color: #555;
        padding: 15px;
        padding-bottom: $padding - 3px;
        border: 0;
        border-radius: 5px;
        position: relative;
        margin-top: 20px;
        transform: translateX(150%);
        transition: transform 0.65s ease-in-out;
        box-shadow: 0 0 5px #999;

        &.open {
            transform: translateX(0%);
        }

        .icon {
            font-size: $icon-size;
            margin-right: $padding;
            position: absolute;
            color: $rendr-wave-2;

            &.success {
                color: $rendr-wave-3;
            }

            &.error {
                color: $electro-tek-red;
            }

            &.warning {
                color: $electro-tek-light-orange;
            }
        }

        .close-icon {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 2px;
            padding: $padding;
            cursor: pointer;
            color: #aaa;
            font-size: 15px;
            z-index: 2;

            &:hover {
                color: #888;
            }
        }

        // Let's keep .title here even though we aren't using it yet.
        // We can remove it when we are sure we will never want titles.
        .title,
        .body {
            padding-left: $padding + $icon-size;
            padding-right: 15px;
            vertical-align: top;
            display: inline-block;
            position: relative;
            top: -2px;
            width: 100%;
            word-break: break-word;
        }

        .title {
            font-weight: bold;
            margin-bottom: 5px;
        }

        &.clickable {
            cursor: pointer;
        }
    }

}
