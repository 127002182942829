$channel-arrow-color: #444;

.channel-arrow {
    line.colored, .marker {
        transition: 100ms stroke ease-in-out, 100ms fill ease-in-out;
    }

    line.colored {
        stroke: $channel-arrow-color;
    }

    .marker {
        fill: $channel-arrow-color;
    }

    &.no-hover:hover {
        line.colored {
            stroke: $channel-arrow-color;
        }
        .marker {
            fill: $channel-arrow-color;
        }
    }


    .channel-arrow.error {
        line.colored {
            stroke: red;
        }
        .marker {
            fill: red;
        }
    }

    &:hover, &.channel-arrow.selected {
        line.colored {
            stroke: #20a8d8;
        }

        .marker {
            fill: #20a8d8;
        }
    }
}
