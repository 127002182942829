@import "../../scss//mmt/mobilemedtek_colors";
@import "../../scss/bootstrap-variables";

.cpt-monitoring-block-view {
    $timestamps-width: 100px;
    $concurrent-count-width: 100px;
    $padding-top: 10px;
    padding-top: $padding-top;
    overflow-y: auto;
    overflow-x: visible;
    position: relative;
    height: calc(100vh - 200px);

    .timestamps {
        width: $timestamps-width;
        position: absolute;

        .stamp {
            transform: translateY(-50%);
            position: absolute;
            right: 10px;
        }
    }

    .lanes {
        margin-left: $timestamps-width;
        margin-right: $concurrent-count-width;
        position: relative;

        .mouse-ruler, .current-time-ruler {
            border-top: 1px solid #fff;
            position: absolute;
            right: 0px;
            left: 0px;

            .session-count {
                position: absolute;
                right: -10px;
                transform: translate(100%, -50%);
                position: absolute;
            }

            .stamp {
                position: absolute;
                left: -10px;
                transform: translate(-100%, -50%);
                position: absolute;
            }
        }

        .current-time-ruler {
            $color: rgba(255, 255, 255, 0.5);
            border-top: 1px solid $color;

            .stamp, .session-count {
                color: $color;
            }
        }

        .lane {
            position: absolute;

            .session {
                border: 1px solid #fff;
                border-radius: 5px;
                position: absolute;
                background: transparentize($color: $rendr-wave-3, $amount: 0.6);
                width: 100%;
                cursor: pointer;
                transition: min-width 0.15s ease-in, min-height 0.15s ease-in;
                overflow: hidden;

                .info {
                    padding: 10px;
                    font-size: 14px;

                    p {
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &.ended-after-day-or-ongoing {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom: none;
                }

                &.started-before-today {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-top: none;
                }

                &.hovered, &.selected {
                    background: $rendr-wave-3;
                }

                &.selected {
                    word-break: break-all;
                }
            }
        }

        .no-studies-msg {
            width: 70%;
            margin: auto;
            text-align: center;
        }
    }
}