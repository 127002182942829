.Users {
    
    .user_row {
        &.deleted {
            background-color: rgba(#000, 0.08);
            font-style: italic;
            td {
                color: darkgray;
            }
        }
    }

}