//TODO: Duplicate css with CPTUserMonitoring component

.cpt-study-monitoring {
    h3 {
        margin: 0;
    }

    hr {
        margin: 15px 0 5px;
    }

    .action-icons {
        i {
            position: relative;
            top: 6px;
            float: right;
            margin-left: 15px;
            font-size: 20px;
            color: rgba(255, 255, 255, 0.8);
            cursor: pointer;

            &:last-child {
                margin-left: 0;
            }

            &:not(.calendar-icon):hover {
                color: white;
            }

            &.disabled, &.disabled:hover {
                color: rgba(255, 255, 255, 0.25);
                cursor: not-allowed;
            }

            &.icon-electrotek-tile {
                font-size: 25px;
                position: relative;
                top: 4px;
                left: 4px;
            }

            &.download-icon {
                position: relative;
                left: 20px;
            }
        }

        .calendar-icon {
            left: 24px;
        }

        .rdt {
            float: right;
            position: relative;
            top: -4px;

            input {
                cursor: pointer;
                font-size: 18px;
                text-align: right;
                // width: 180px;
            }
        }
    }

}