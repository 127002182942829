@import "../../../scss/z-index.scss";
@import "../../../scss/mmt/mobilemedtek_colors";

#GraphChannelMenu {
    position: absolute;
    top: 0px;
    left: 90px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    visibility: collapse;
    transition: visibility 300ms;
    z-index: $channel-menu-z-index;

    li {
        float: left;
        opacity: 0;
        transform: translateX(-50%) scale(0.8);
        transition: opacity 200ms, transform 200ms;
        transition-timing-function: ease-in;

        .icon-electrotek-measure {
            transform: rotate(-90deg);
        }

        &:nth-child(1) {
            transition-delay: 0ms;
        }
        &:nth-child(2) {
            transition-delay: 25ms;
        }
        &:nth-child(3) {
            transition-delay: 50ms;
        }
        &:nth-child(4) {
            transition-delay: 75ms;
        }
        &:nth-child(5) {
            transition-delay: 100ms;
        }
    }

    &.show {
        visibility: visible;
        transition: visibility;
    }

    &.show li {
        opacity: 1;
        transform: scale(1);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;

        &:nth-child(1) {
            transition-delay: 0ms;
        }
        &:nth-child(2) {
            transition-delay: 50ms;
        }
        &:nth-child(3) {
            transition-delay: 100ms;
        }
        &:nth-child(4) {
            transition-delay: 150ms;
        }
        &:nth-child(5) {
            transition-delay: 200ms;
        }
    }

    li button {
        border: none;
        display: block;
        color: $color-white;
        background-color: $rendr-wave-2;
        text-align: center;
        margin-right: 10px;
        padding: 0;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        font-size: 24px;

        &:before {
            display: inline-block;
        }

        &:focus {
            background-color: $rendr-wave-3;
            outline: none;
        }

        &:hover {
            background-color: $rendr-wave-3;
        }

        &:hover.disabled {
            background-color: $rendr-wave-2;
            cursor: default;
        }

        &.badge {
            font-size: 12px;
            border-radius: 10px;
            height: initial;
            width: initial;
            padding: 8px 12px;
            margin-top: 11px;
        }
    }
}
