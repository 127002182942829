@import "../../../../scss/z-index.scss";
@import "../../../../scss/mmt/mobilemedtek_colors";

.event-markers {
    position: absolute;
    z-index: $events-z-index;
    pointer-events: none;
    overflow: hidden;

    .event-marker-container {
        $tag-margin: 5px;
        $handle-width: 15px;

        .event-marker {
            transition: border-color 0.25s ease-in-out;
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            pointer-events: all;

            .ball-container {
                width: 100%;
                position: relative;

                .ball {
                    border-radius: 50%;
                    border-width: 0;
                    position: absolute;
                }
            }

            .move-handle {
                position: absolute;
                left: calc(0px - #{$handle-width / 2});
                width: calc(100% + #{$handle-width});
                height: 100%;
                z-index: 2;
            }

            .drag-left-handle {
                position: absolute;
                left: calc(0px - #{$handle-width / 2});
                width: $handle-width;
                height: 100%;
                z-index: 2;
            }

            .drag-right-handle {
                position: absolute;
                left: calc(100% - #{$handle-width / 2});
                width: $handle-width;
                height: 100%;
                z-index: 2;
            }
        }

        &.saving .event-marker {
            cursor: progress;
        }

        .event-marker::before,
        .event-marker::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 15px;
            cursor: pointer;
        }

        .event-marker::before {
            left: calc(0px - #{$handle-width / 2});
        }

        .event-marker::after {
            left: calc(100% - #{$handle-width / 2});
        }

        .info-box {
            transition: border-color 0.25s ease-in-out;
            user-select: none;
            display: none;
            width: 270px;
            padding: 10px;
            padding-top: 30px;

            position: absolute;
            font-size: 14px;
            z-index: 3;
            pointer-events: all;
            cursor: default;
            background: rgba(0, 0, 0, 0.75);
            border-radius: 5px;


            .nav-title-bar {
                z-index: 4;
                position: absolute;
                top: -1px;
                left: -1px;
                width: 270px;
                height: 40px;
                border-radius: 0;
                cursor: pointer;
                text-align: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                text-align: center;
                padding-top: 10px;

                .nav {
                    position:absolute;
                    padding: 13px;
                    width: 45px;
                    height: 39px;
                    top: -1px;
                    color: $rendr-wave-2;
                }

                .nav-left {
                    left: -1px;
                    border-top-left-radius: 5px;
                }

                .nav-right {
                    right: -1px;
                    border-top-right-radius: 5px;
                }

                .nav-left:hover {
                    color: $rendr-wave-3;
                    background-color: rgba(255, 255, 255, 0.1)
                }
                
                .nav-right:hover {
                    color: $rendr-wave-3;
                    background-color: rgba(255, 255, 255, 0.1)
                }

            }

            &.inside {
                transform: translateX(-50%);
            }

            &.outside.right {
                transform: translateX(10px);

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -11px;
                    width: 11px;
                    border: inherit;
                    border-bottom: 0;
                }
            }

            &.outside.left {
                transform: translateX(-10px);

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -11px;
                    width: 11px;
                    border: inherit;
                    border-bottom: 0;
                }
            }

            .event-value {
                overflow: hidden;
                word-wrap: break-word;
                margin-bottom: 10px;
            }

            .select-wrapper {
                position: relative;

                .select {
                    outline: none;
                    cursor: pointer;
                    padding: 5px 10px;
                    padding-right: 30px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #fff2;
                }
            }

            .measurement {
                p {
                    margin-bottom: 10px;
                }

                dd,
                dt {
                    display: inline-block;
                    margin-bottom: 10px;
                }

                dd {
                    float: right;
                }
            }

            .actions {
                display: flex;
                margin: 0 -5px;
                flex-wrap: wrap;
                justify-content: center;

                i {
                    color: $rendr-wave-2;
                    display: inline-block;
                    margin: 5px;
                    padding: 10px;
                    font-size: 14px;
                    cursor: pointer;
                    border-radius: 50%;
                    width: 34px;
                    background: rgba(255, 255, 255, 0.2);

                    &::before {
                        position: relative;
                    }

                    &:hover {
                        color: $rendr-wave-3;
                        background: rgba(255, 255, 255, 0.35);
                    }
                }

                &.navigation {
                    i {
                        background: transparentize($rendr-wave-2, 0.3);
                        margin-bottom: 0;
                        margin-top: 10px;

                        &:hover {
                            background: transparentize($rendr-wave-2, 0.15);
                        }
                    }
                }
            }

            .duration-change {
                position: relative;
                top: -1px;

                .duration-wrapper {
                    position: relative;
                    top: 1px;
                    display: inline-block;
                    margin-left: 5px; 
                    width: 100px;
                    text-align: right;

                    .duration {
                        border-radius: 2px;
                        padding: 1px 4px 2px;
                        transition: background 500ms ease-in-out;

                        &.highlight {
                            transition: none;
                            background: transparentize($color: $rendr-wave-2, $amount: 0.55);
                        }
                    }
                }
            }

            .button {
                font-size: 14px;
                padding: 8px 16px;
                line-height: 100%;

                background: transparent;
                border-radius: 5px;
                border: 1px solid #fff2;
                color: #fff;

                &:hover {
                    background: #fff2;
                }

                &.disabled {
                    background: #fff1;
                    cursor: not-allowed;
                }
            }
        }

        &.selected {
            .event-marker,

            .event-marker {
                background: #{$electro-tek-orange}33 !important;

                .ball {
                    background: $electro-tek-orange;

                    &.disabled {
                        background: #{$electro-tek-orange}66;
                    }
                }
            }
        }

        &.saving .info-box {
            cursor: progress;

            .actions {
                i {
                    cursor: progress;
                }
            }
        }

        &:hover .info-box,
        &.selected .info-box {
            display: inline-block;
        }

        &.selected .move-handle {
            cursor: move;
        }

        &.selected .drag-left-handle,
        &.selected .drag-right-handle {
            cursor: ew-resize;
        }

        &.creating {
            z-index: 4;
            pointer-events: auto;
            cursor: ew-resize;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);

            .create-event-instructions {
                display: block;
            }

            .event-marker::before,
            .event-marker::after {
                cursor: ew-resize;
            }
        }

        .create-event-instructions {
            user-select: none;
            width: 100%;
            display: none;
            font-size: 22px;
            position: absolute;
            padding-top: 20px;
            z-index: 1;
            text-align: center;

            i {
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
}
