@import "../../scss/mmt/mobilemedtek_colors";

.study-card {
    $card-height: 320px;

    height: $card-height + 20px;
    min-width: 270px;
    max-width: 450px;

    .text-truncate {
        width: 270px;
    }

    .patient-icon {
        color: #fff;
        font-size: 80px;
        cursor: pointer;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: initial;
    }

    .patient-icon:hover {
        color: rgba($color-white, 0.75) !important;
    }

    .patient-name {
        text-align: center;
        margin: 95px 0 20px 0;
    }

    .open-study-button {
        margin-top: 15px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .react-card-front .MuiCardContent-root {
        background: $rendr-wave-2;
        height: $card-height;
    }

    .react-card-back .MuiCardContent-root  {
        background: $rendr-wave-3;
        color: $electro-tek-white;
        height: $card-height;
    }

    .float-right {
        float: right;
    }
}
