.devicelogs {
    .extra-info {
        color: rgba(255, 255, 255, 0.5);
        // white-space: nowrap;

        .info-item {
            font-size: 14px;
            display: inline-block;

            &:after {
                content: '|';
                margin: 0 15px;
            }

            &:last-child:after {
                content: '';
            }
        }
    }
}
