@import "../../../scss/mmt/mobilemedtek_colors";

.quick-notes-dropdown {
    position: absolute;
    overflow: hidden;
    top: 42px;
    background-color: #ddd;
    text-decoration: none;
    border: 0;
    right: 150px;
    z-index: 3999;

    .quick-note {
        display: block;
        text-align: left;
        width: 100%;
        border: none;
        cursor: pointer;
        padding: 14px;
        background-color:#dddddd;

        &:focus {outline:0;}

        &:hover {
            background-color: white;
        }

        .icon {
            color: $rendr-wave-2;
            font-size: 18px;
            position: relative;
            top: 2px;
            padding-right: 15px;
        }

        .quick-note-value {
            font-size: 14px;
            text-transform: uppercase;
            color: black;
        }
    }

    .selected {
        background-color: white;
        border: none;
    }
}
