.print-modal {
    .body {
        padding: 20px;

        input {
            position: relative;
            top: -2px;
            margin-right: 10px;
        }

        button {
            margin-left: 20px !important;
        }
    }
}
