.version-info {
    display: inline-block;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 10px;

    .header {
        font-size : larger;
        font-weight : 800;
    }
}
