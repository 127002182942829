@import "../../scss/z-index.scss";
@import '../../scss/mmt/mobilemedtek_colors';
@import '../../scss/mmt/electrotek_icons/variables';

.eeg-container {
    position: relative;
    top: 6px;

    .btn-graph-back-container {
        margin: 0px;
        padding: 0px;
        text-align: center;
        border-right: 1px solid $color-gray-dv;

        .btn-graph-back {
            color: $color-text-gray;
            font-size: 20px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .monospace {
        font-family: 'Lucida Console', Monaco, monospace;
    }

    &.fillspace {
        margin: -30px -24px;
        height: calc(100vh - 50px);

        &.controls-minimized {
            height: 100vh;
        }

        & > div {
            overflow: hidden;
        }
    }

    &.fullscreen {
        margin: initial;
        z-index: 99999;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;

        & > div {
            overflow: hidden;
        }
    }

    .page.active {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        #GraphPanel {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: $graph-panel-z-index;

            &.creating::after {
                content: '';
                overflow: visible;
                border: 2px solid $electro-tek-orange;
                pointer-events: none;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 30px;
                top: 0;
                z-index: $create-event-mode-border-z-index;
            }

            #myEEG {
                flex-grow: 1;
            }

            .mouse-canvas {
                z-index: $play-cursor-z-index;
            }

            &.hidden {
                display: none;
            }
        }
    }

    .graph-canvas {
        position: relative;
        z-index: $trace-lines-z-index;
    }

    .scrub-canvas {
        background: $color-white;
        cursor: pointer;
        height: 0px;
        width: 100%;
    }

    .panel-block {
        white-space: nowrap;
    }
}
