.study-recording-card {
    background: rgba(0, 0, 0, 0.4);
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
    width: 300px;

    .header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        .MuiCardHeader-title {
            font-size: 16px;
        }
    }

    .body {
        padding: 15px 15px 18px 15px;

        .saved-status {
            opacity: 0.5;
            margin-left: 10px;
        }

        .right {
            float: right;
        }

        textarea[disabled] {
            background: rgba(255, 255, 255, 0.5);
            cursor: not-allowed;
        }
    }
}
