@import "../../scss/mmt/mobilemedtek_colors";

.profile{
    box-sizing: border-box;
    -webkit-transition: 0.3s cubic-bezier(0.75, 0, 0.2, 1), color 0.3s ease;
    transition: 0.3s cubic-bezier(0.75, 0, 0.2, 1), color 0.3s ease;
    padding-bottom: 50px;

    .MuiCard-root {
        background-color: rgba(35, 35, 35, 0.1);
        background-image: none;

        .MuiCardHeader-root {
            background-color: $rendr-wave-2;

            &.header-top {
                border-radius: 5px 5px 0 0;
            }
            &.header-middle {
                border-radius: 0;
            }
        }
    }

    .profile-block {
        display: block;
        position: relative;
        margin: auto;
    }

    .profile-card {
        width: calc(100%);
        padding-top: 100px;
        margin: 70px auto 30px;
        background-color: $rendr-wave-2;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        border-radius: 10px;
    }

    .profile-pic {
        display: false;
        position: absolute;
        top: -90px;
        left: 50%;
        right: false;
        bottom: false;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 180px;
        width: 180px;
        border: 10px solid $rendr-wave-2;
        border-radius: 100%;
        background: url('profile.png') center no-repeat;
        background-size: cover;
    }

    .profile-name-container {
        margin: 0 auto 10px;
        padding: 10px;
        text-align: center;
        overflow-wrap: break-word;
    }

    .user-name {
        font-family: 'Montserrat';
        font-size: 32px;
        letter-spacing: 3px;
        font-weight: 800;
        line-height: 30px;
        margin-bottom: 12px;
    }

    .user-desc {
        letter-spacing: 1px;
        color: $rendr-wave-4;
        font-weight: bolder;
    }
}
