// Links, buttons, and more within the dropdown menu
.dropdown-item {
    position: relative;
    padding: 10px 20px;
    border-bottom: 1px solid $dropdown-border-color;

    &:last-child {
        border-bottom: 0;
    }

    i {
        display: inline-block;
        width: 20px;
        margin-right: 10px;
        margin-left: -10px;
        color: $dropdown-border-color;
        text-align: center;
    }

    .badge {
        position: absolute;
        right: 10px;
        margin-top: 2px;
    }
}

// Dropdown section headers
.dropdown-header {
    padding: 8px 20px;
    background: $dropdown-divider-bg;
    border-bottom: 1px solid $dropdown-border-color;

    .btn {
        margin-top: -7px;
        color: $dropdown-header-color;

        &:hover {
            color: $body-color;
        }

        &.pull-right {
            margin-right: -20px;
        }
    }
}

.dropdown-menu-lg {
    width: 250px;
}
.app-header {
    .navbar-nav {
        .dropdown-menu {
            position: absolute;
            z-index: 2000;
        }
        // Menu positioning
        //
        // Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
        // menu with the parent.
        .dropdown-menu-right {
            right: 0;
            left: auto; // Reset the default from `.dropdown-menu`
        }

        .dropdown-menu-left {
            right: auto;
            left: 0;
        }
    }
}
