@import "../../scss/mmt/mobilemedtek_colors";
@import "../../scss/z-index.scss";

.graph-control {
    $controls-row-height: 80px;

    position: relative;
    margin: 0;
    width: 100%;
    height: $controls-row-height;

    background-color: $color-panel-bg;
    color: $color-gray-dk;
    z-index: $graph-control-z-index;

    &.minimized {
        height: $controls-row-height / 2;
    }

    button {
        outline: none;
    }
    button {
        background: transparent;
        border: none;
        color: $rendr-wave-2;
        display: block;
        height: 100%;
        margin: auto;
    }

    .row {
        height: 100%;
        flex-wrap: nowrap;
        margin-left: 3px;
    }

    .controls-minimizer-container {
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid $electro-tek-light-gray;
        height: 100%;
        width: 42px;
        color: $rendr-wave-2;
        background-color: $color-gray-lt;
        cursor: pointer;
        text-align: center;
        font-size: 30px;
    }

    .patient-container {
        display: flex;
        align-items: center;
        height: 100%;

        .patient-icon {
            order: 1;
            margin: 0 5px;
            font-size: 30px;
        }

        .patient-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            order: 2;
            margin: 0px;
            width: 100%;
            white-space: nowrap;

            %patientFields {
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1rem;
            }

            .patient-name {
                @extend %patientFields;
                font-weight: bolder;
                line-height: 1.5rem;
            }
            .patient-id {
                @extend %patientFields;
                font-size: 95%;
            }
            .patient-dob {
                @extend %patientFields;
                font-size: 75%;
            }
        }
    }

    .time-display {
        $time-font: 'Lucida Console', Monaco, monospace;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        height: 100%;
        font-weight: 200;
        position: relative;
        left: 20px;

        %timeFields {
            margin: 0px;
            line-height: 115%;
            white-space: nowrap;
        }

        .time-title {
            @extend %timeFields;
            order: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            color: $color-gray-md;
        }

        .playback-time {
            @extend %timeFields;
            order: 2;
            font-family: $time-font;
            font-size: 14px;
        }

        .total-time {
            @extend %timeFields;
            order: 3;
            font-family: $time-font;
            font-size: 14px;
        }

        .icon-electrotek-dot {
            font-size: 22px;
        }

        .icon-dot {
            display: block;
            position: absolute;
            top: 0;
            right: 13px;
            font-size: 50px;
            color: $color-gray-md;

            &:before {
                margin: 0;
                width: 15px;
            }
        }

        &.active {
            .dot {
                color: $color-orange-lt;
                text-shadow: $color-orange-lt 0 0 10px;
            }
        }
    }
   
    $sliderHeight: 150px;

    #speedControl {
        background: transparent;
        position: relative;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

        .speed-icon-text {
            color: darken($rendr-wave-2,10%);
            font-size: 11px;
            font-weight: bold;
            position: relative;
            text-align: center;
            top: -150px;
            white-space: nowrap;

            &.minimized {
                font-size: 10px;
                top: -175px;
            }
        }

        button {
            position: relative;
            top: -215px;

            .speed-icon {
                color: $rendr-wave-2;
                display: block;
                font-size: 44px;
                margin: auto;
                position: relative;
                top: -10px;
                transform: scale(1.5);

                &.minimized {
                    font-size: 32px;
                    top: -6px;
                    svg {
                        top: 70%;
                    }
                }
                svg {
                    fill: $rendr-wave-2;
                    position: absolute;
                    left: 50%;
                    top: 60%;
                    transform: translate(-50%, -50%);
                    width: 130%;
                }
            }
        }

    }

    .speed-slider-container {
        position: relative;
        visibility: hidden;
        top: -$sliderHeight - $controls-row-height + 25;

        &.minimized {
            top: -$sliderHeight - $controls-row-height + 15;
        }

        &.slider-open {
            visibility: visible;
        }

        .slider-control-elements {
            margin: auto;
            padding: 30px 0px 20px 0px; 
            background: transparentize($color: $rendr-wave-2, $amount: .4);
            width: 50px;
            border-radius: 30px;
        }

        .speed-slider {
            margin: auto;
            z-index: $graph-speed-slider-z-index;
            height: $sliderHeight;
            width: 30px;
        }

        .rc-slider-handle {
            background: $rendr-wave-2 !important;
            width: 20px;
            height: 20px;
            left: 2px;
        }

        .rc-slider-vertical {
            height: $sliderHeight - 10;
            left: 50%;
            transform: translateX(-50%);
        }

        .speed-slider-value-container {
            $containerHeight: 55px;

            position: absolute;
            top: -$containerHeight - 5;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.65);
            color: white;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.85);
            
            .speed-slider-value {
                font-size: 18px;
                white-space: nowrap;
                padding: 10px 20px;
            }
        }
    }

    .play-controls {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        min-width: 165px;

        .prev-next-btns, .prev-next-important-btns, .play-btn {
            color: $electro-tek-white;
            border: none;
        }

        .prev-next-btns, .play-btn {
            background: $rendr-wave-2;

            &:hover {
                background: lighten($rendr-wave-2, 10%);
            }

            &:active {
                background: lighten($rendr-wave-2, 15%);
            }

            &[disabled], &[disabled]:hover, &[disabled]:focus {
                background: desaturate(lighten($rendr-wave-2, 25%), 25%);
                cursor: not-allowed;
            }
        }

        .prev-next-important-btns {
            background: gold;

            &:hover {
                background: lighten(gold, 10%);
            }

            &:active {
                background: lighten(gold, 15%);
            }

            &[disabled], &[disabled]:hover, &[disabled]:focus {
                background-color: desaturate(lighten(gold, 25%), 25%);
                border-color: desaturate(lighten(gold, 25%), 25%);
                cursor: not-allowed;
            }
        }

        .prev-next-btns, .prev-next-important-btns {
            color: $electro-tek-white;
            $prev-next-btns-height: 45px;
            $prev-next-btns-width: 45px;

            position: relative;
            width: $prev-next-btns-width;
            height: $prev-next-btns-height;

            border-radius: 50%;

            &.minimized {
                height: $prev-next-btns-height * 0.75;
                width: $prev-next-btns-width * 0.75;
            }

            .icon {
                $font-size: 40px;

                position: absolute;
                font-size: $font-size;
                padding: 0;
                margin: 0;
                top: 52%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.icon-electrotek-chevron-left {
                    left: 49%;
                }

                &.icon-electrotek-chevron-right {
                    left: 51%;
                }

                &.minimized {
                    font-size: $font-size * 0.75;
                    top: 50%;
                }
            }
        }

        .play-btn {
            $play-btn-font-size: 60px;
            $play-btn-height: 70px;
            $play-btn-width: 70px;

            position: relative;
            height: $play-btn-height;
            width: $play-btn-width;
            border-radius: 50%;
            cursor: pointer;

            &.minimized {
                height: $play-btn-height / 2;
                width: $play-btn-width / 2;
            }

            .icon {
                position: absolute;
                font-size: $play-btn-font-size;
                padding: 0;
                margin: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.play {
                    left: 55%;
                    top: 52%;
                }

                &.pause {
                    font-size: $play-btn-font-size * 0.8;
                    top: 51%;
                }

                &.minimized {
                    font-size: $play-btn-font-size / 2;
                    
                    &.play {
                        left: 57%;
                    }

                    &.pause {
                        top: 52%;
                        left: 52%;
                    }
                }
            }

            &.focused {
                background: gold;
                border-color: gold;

                &:hover {
                    background: lighten(gold, 10%);
                }
    
                &:active {
                    background: lighten(gold, 15%);
                }
    
                &[disabled], &[disabled]:hover, &[disabled]:focus {
                    background: desaturate(lighten(gold, 25%), 25%);
                    cursor: not-allowed;
                }
            }
        }
    }

    .focused-review {
        $focused-review-font-size: 40px;

        .icon {
            font-size: $focused-review-font-size;
            color: $rendr-wave-2;

            &:hover {
                color: $rendr-wave-3;
            }

            &.gold {
                color: gold;

                &:hover {
                    color: lighten(gold, 10%);
                }
            }
        }
    }

    .fullscreen-control {
        $fullscreen-font-size: 40px;
        justify-content: center;
        height: 100%;

        button {
            display: block;
            border: none;
            background: transparent;
            height: 100%;
            margin: auto;
        }

        .icon {
            font-size: $fullscreen-font-size;
            color: $rendr-wave-2;
            position: relative;
            top: 2px;

            &.minimized {
                font-size: $fullscreen-font-size * 0.7;
            }

            &:hover {
                color: $rendr-wave-3;
            }

            &[disabled],
            fieldset[disabled] &,
            &[disabled]:hover,
            &[disabled]:focus {
                color: $color-black-50;
            }
        }
    }

    .video-control {

        .video-btn {

            .icon {
                $video-font-size: 40px;
                font-size: $video-font-size;

                &:hover {
                    color: $rendr-wave-3;
                }
            }

            &[disabled] {
                cursor: not-allowed;

                .icon {
                    color: $electro-tek-gray;
                }
            }
        }

        .video-message {
            font-size: 11px;
            display: inline-block;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            position: relative;
            top: 35px;
            left: 25px;
            line-height: 10px;
            color: red;
            transform: translateX(-50%);
        }
    }
}
