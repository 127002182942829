.device-form {
    .input-copy-text {
        border: 0;
        background: transparent !important;
        padding: 0;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        display: inline-block;

        &:focus {
            color: rgba(255, 255, 255, 0.8);
            box-shadow: none;
        }

        &:hover {
            color: #fff;
        }
    }
}
