.search-patient {
    width: 100%;

    .form-group {
        padding: 0 15px;
    }

    table {
        th, td {
            padding: 5px;

            &.limit-width {
                max-width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
