.amplifier-info-row {
    td {
        padding: 0 !important;
        border: 0;
    }

    .info {
        margin: 20px;
        padding: 20px;
        width: 400px;
        background: rgba(0, 0, 0, 0.25);

        p {
            span {
                word-break: break-all;
                max-width: 220px;
            }

            &:last-child {
                margin: 0;
            }

            &::after {
                content: '';
                clear: both;
                display: table;
            }
        }
    }
}
