// * MOBILEMEDTEK CONFIDENTIAL                                                 |
// * -------------------------                                                 |
// * Copyright 2018 MobileMedTek. All rights reserved.                         |
// *                                                                           |
// * NOTICE:  All information contained herein is, and remains                 |
// * the property of MobileMedTek. The intellectual and                        |
// * technical concepts contained herein are proprietary to MobileMedTek       |
// * and its suppliers and may be covered by U.S. and Foreign Patents,         |
// * patents in process, and are protected by trade secret or copyright law.   |
// * Dissemination of this information or reproduction of this material        |
// * is strictly forbidden unless prior written permission is obtained         |
// * from MobileMedTek.

/* Variables */
$box-radius: 6px;

// Rendr colors
$rendr-wave-0: #1c3846;
$rendr-wave-1: #174a8c;
$rendr-wave-2: #2ba9a0;
$rendr-wave-3: #71c2ae;
$rendr-wave-4: #bcddc7;

$color-green-50: rgba(85, 196, 95, 0.5);

// Blues
$electro-tek-blue: #a2e0ee;

// Reds
$electro-tek-light-red: #ff5154;
$electro-tek-red: #e54749;
$electro-tek-dark-red: #722324;

// Oranges
$electro-tek-light-orange: #f78e61;
$electro-tek-orange: #ff4e00;
$electro-tek-dark-orange: #c53d03;

// Grays
$electro-tek-white: #ffffff;
$electro-tek-very-light-gray: #e9e9e9;
$electro-tek-light-gray: #b8b8b8;
$electro-tek-gray: #909090;
$electro-tek-dark-gray: #666666;
$electro-tek-black: #232323;
$electro-tek-box-bg: rgba($electro-tek-black, 0.1);

// Wire colors (used in placement and impedance area)
$wire-blue: #0091ff;
$wire-orange: #ff270f;
$wire-yellow: #d4ff00;
$wire-green: #30ff19;

// Other
$color-graph: #000000;
$color-text-black: #232323;
$color-text-gray: #525252;
$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-25: rgba(0, 0, 0, 0.25);
$color-black-50: rgba(0, 0, 0, 0.5);
$color-white: #fff;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-panel-bg: #f0f0f0;
$color-gray-lt: #dfdfdf;
$color-gray-md: #909090;
$color-gray-dk: #333333;
$color-gray-dv: #ccc;
$color-orange-lt: #ff4e00;
$color-orange-dk: #9d3001;
