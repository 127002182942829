@import "../../../scss/z-index";

.loading-packets-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(50, 50, 50, 0.5);
    z-index: $message-box-z-index;
    padding: 50px 75px;
    text-align: center;
    border-radius: 5px;

    p {
        margin: 0;
    }
}
