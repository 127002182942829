@import '../mobilemedtek_colors';

/* Variables */
$box-radius: 6px;

body {
    font-size: 1rem;
}

.font {
    font-family: 'proxima-nova', sans-serif;
}

h2 {
    font-size: 2.5rem;
    font-weight: 200;
    margin: 40px 0 60px 0;
}

h3 {
    margin-bottom: 1.5rem;
}

a {
    color: $rendr-wave-3;

    &:hover {
        color: $rendr-wave-4;
        text-decoration: none;
    }
}

.dim {
    opacity: 0.5;
}

.input-group-lg .input-lg {
    padding: 10px 20px;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn,
.input-group-lg > .input-group-btn > .btn-icon {
    font-size: 24px;
    height: 60px;
    background: none;
    border: none;

    .icon {
        font-size: 30px;
    }
}

/* Bootstrap Theme Override */
.btn {
    border: none;
    /*margin: 40px 20px;*/
    outline: none !important;
    text-align: center;
    cursor: pointer;

    &:disabled {
        cursor: auto;
    }
}
.btn:active {
    opacity: 0.5;
}
.btn-primary,
.btn-secondary,
.btn-default,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    position: relative;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 10px 30px;
    font-size: 18px;
    font-weight: bold;

    .icon {
        font-size: 18px;

        &.left {
            margin-left: -10px;
        }
        &.right {
            margin-right: -10px;
        }
    }
}
.btn-primary {
    border: 2px solid $color-white;
    background-color: $color-black-25;
}
.btn-primary:hover {
    border-color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.15);
}
.btn-secondary {
    min-width: 100px;
    color: $color-white;
    border: 2px solid $color-black-50;
    background-color: $color-black-25;
    padding: 8px 28px;
}
.btn-secondary:hover {
    border-color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.15);
}
.btn-info {
    background-color: transparent;
}
.btn-info:hover {
    background-color: $color-white;
}
.btn-default {
    background-color: $color-gray-md;
}
.btn-default:hover {
    background-color: #666464;
}
.btn-success {
    background-color: $rendr-wave-3;
    .icon {
        color: rgba(51, 51, 51, 0.5);
    }
}
.btn-success-sm {
    padding: 5px 15px;
    font-size: 14px;
}
.btn-success:hover {
    background-color: $rendr-wave-4;
}
.btn-warning,
.btn-danger {
    background-color: $color-orange-lt;
}
.btn-warning:hover,
.btn-danger:hover {
    background-color: $color-orange-dk;
}
.black-10 {
    background-color: $color-black-10;

    .form-control {
        color: $color-white;
    }
    .form-control::-moz-placeholder {
        color: $color-gray-md;
    }
    .form-control:-ms-input-placeholder {
        color: $color-gray-md;
    }
    .form-control::-webkit-input-placeholder {
        color: $color-gray-md;
    }
}
.btn-icon,
.btn-primary .btn-icon {
    position: relative;
    color: $color-white-50;
    font-weight: normal;
    cursor: pointer;
    border-radius: 0;
    /*line-height: 0.5;*/
    margin: 0;
    padding: 20px 20px;
    box-shadow: none !important;

    .icon {
        font-size: 40px;
    }

    &,
    &:active,
    &[disabled],
    fieldset[disabled] & {
        background-color: transparent;
    }
    &,
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }
    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        background-color: transparent;
    }
    &:active {
        color: $color-white-20;
    }
    &[disabled],
    fieldset[disabled] &,
    &[disabled]:hover,
    &[disabled]:focus {
        color: $color-gray-dk;
        text-decoration: none;
    }

    .badge {
        position: absolute;
        right: 0;
        padding-left: 10px;
        padding-right: 10px;

        &.tag {
            font-size: 12px;
            font-weight: bold;
        }
    }

    em {
        display: block;
        font-style: normal;
        font-weight: 600;
        /*padding-top: 10px;*/
    }
}

.mode-symbol {
    position: relative;
    display: inline-block;
    background: $color-white-10;
    border-radius: 50%;
    vertical-align: middle;
}

.mode-btn {
    font-size: 18px;
    width: 300px;
    height: 300px;
    padding: 0;
    border: none;
    outline: none;
    transition: all ease-in-out 100ms;

    span {
        font-size: 48px;
        font-weight: bold;
    }

    em {
        color: $color-white-50;
        font-weight: normal;
        font-style: normal;
    }
}
.mode-btn:hover {
    background: $color-white-20;
}
.mode-btn:active {
    outline: none;

    .halo {
        border-style: double;
        border-width: 50px;
        width: 150%;
        height: 150%;
        top: -25%;
        left: -25%;
        opacity: 0.1;
    }
}

.halo {
    position: absolute;
    display: block;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    opacity: 1;
    transition: all ease-out 100ms;
    /*transition: border ease-out 200ms;*/
}
.halo-green {
    border-color: rgba(209, 207, 32, 0.8);
    box-shadow: rgba(209, 207, 32, 0.75) 0 0 5px 2px;
}
.halo-orange {
    border-color: rgba(255, 108, 0, 0.8);
    box-shadow: rgba(255, 108, 0, 0.75) 0 0 5px 2px;
}
.halo:hover {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
    opacity: 1;
}

.user {
    display: inline-block;
    float: left;
    width: 50px;
    height: 50px;
    padding-top: 5px;
    font-size: 80px;
    color: $color-gray-md;
    background: $color-white;
    border: solid 1px $color-white;
    border-radius: 4px;
    text-align: center;
}

.page {
    display: none;
    width: 100%;
    margin: 0 auto;

    &.active {
        display: block;
    }

    .search {
        margin-bottom: 10px;
        width: 100%;
    }

    .results {
    }

    .results .row.header {
        color: $color-gray-md;
        font-weight: normal;
        padding: 20px 30px;

        &:hover {
            background: transparent;
        }
    }

    .results .row {
        margin: 0;
        padding: 20px;
        cursor: pointer;
        transition: background ease-in-out 100ms;

        & div {
            line-height: 50px;
        }

        &:hover {
            background: $color-white-10;
        }

        &.active {
            background: rgba($rendr-wave-3, 0.35);
        }
        &.active:hover {
            background: $rendr-wave-3;
        }
    }
}

.page > .header {
    border: none;
    border-radius: 0;
    text-align: center;

    .navbar-logo {
        margin: 0 auto;
        height: 120px;
        width: 120px;
        line-height: 120px;
        vertical-align: middle;
        color: #fff;
    }

    .navbar-collapse {
        height: 120px;
        line-height: 120px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .logo {
        vertical-align: middle;
        text-align: center;
        width: 159px;
        height: 67px;
        /*background: url(../Images/logo.png) no-repeat;*/
    }

    .mode-symbol {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-weight: bold;
    }
}

.main {
    //background: #fff;
    padding: 30px 0;
}

.app {
    width: 100%;
    display: flex;
    background-image: radial-gradient(
        farthest-corner at 0px 0px,
        rgba(#164158, 0.8) 0%,
        rgba(#2b96cc, 0.8) 50%,
        rgba(#2b96cc, 0.8) 60%,
        rgba(#164158, 0.8) 100%
    );
    background-attachment: fixed;
    color: $electro-tek-white;
}

.card {
    border: none;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.25);
}

.p-3.card {
    border: none;
    .card-body {
        padding: 0;
    }
}

.card-header {
    font-size: 1.6em;
    border-bottom: none;
    .btn {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.table {
    background-color: $electro-tek-box-bg;
    font-feature-settings: 'tnum';
    border-radius: $border-radius;

    //th.min-width-column {min-width : 55px};

    tr.expanded {
        background-color: $table-bg-hover;
    }

    td {
        border-top: 1px solid $color-black-25;
        div,span {
            i {
                color: $rendr-wave-3;
            
                &:hover {
                    color: $rendr-wave-4;
                }
            }
        }
    }

    td,
    th {
        color: #fff;
    }

    thead {
        border-bottom: 1px solid rgba($electro-tek-white, 0.25);
        background-color: $rendr-wave-3;

        th {
            border: none;
            white-space: nowrap;
        }
    }
    thead th:first-of-type {
        border-top-left-radius: $border-radius;
    }
    thead th:last-of-type {
        border-top-right-radius: $border-radius;
    }

    &.table_sideways {
        background: none;

        tbody {
            th {
                text-align: right;
                border-right: 1px solid rgba($electro-tek-white, 0.25);
                // table styles will make the column take up as much as it needs
                width: 1px;
            }
            td {
                text-align: left;
            }
        }
    }
}

.sortable-table th {
    &.sortable {
        cursor: pointer;
        user-select: none;

        .fa {
            font-size: 1.25em;
            padding-left: 5px;
            vertical-align: middle;
            position: relative;
            float: right;
        }
        .fa-sort-down {
            top: -1px;
        }
        .fa-sort-up {
            top: 7px;
        }
    }
}

#HomePage {
    display: block;
    margin: 0 auto;

    .header {
        // background: url(/assets/img/logo.png) no-repeat center center $color-black-10;
    }
}

.field-validation-error,
input.input-validation-error,
.validation-summary-errors {
    color: $color-orange-lt;
}

.field-validation-valid {
    display: none;
}

input[type='checkbox'].input-validation-error {
    border: 0 none;
}

.validation-summary-valid {
    display: none;
}

.old-progress-bar {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -11px;
    width: 400px;
    height: 22px;
    border-radius: 4px;
    background-color: $color-gray-lt;
}

.old-progress-bar div {
    height: 100%;
    text-align: right;
    line-height: 22px; /* same as #progressBar height if we want text middle aligned */
    width: 0;
    color: $color-white;
    background-color: $rendr-wave-4;
    border-radius: 4px 0 0 4px;
}

.old-progress-ani div {
    // background-image: url(/assets/img/progress-ani.gif);
    border-radius: 4px;
    //border-right: 1px solid #AAA;
}

// .sidebar-fixed {
//     .main {
//         margin-left: 0;
//     }

//     &.sidebar-minimized {
//         .sidebar {
//             & + .main {
//                 margin-left: 50px;
//             }
//         }

//         .sidebar-minimizer .sidebar-minimizer-label {
//             display: none;
//         }
//     }

//     .sidebar {
//         background-color: $rendr-wave-0;
//         height: calc(100vh - 55px);
//         z-index: $sidebar-z-index;

//         & + .main {
//             margin-left: 200px;
//         }

//         .nav-link {
//             &:hover {
//                 background: $rendr-wave-3 !important;

//                 i {
//                     color: $color-white !important;
//                 }
//             }

//             &:active i,
//             &.active i {
//                 color: $rendr-wave-4;
//             }
//         }

//         .sidebar-minimizer {
//             //    display: block;
//             //    width: 100%;
//             // padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
//             font-size: 11px;
//             font-weight: 600;
//             color: $sidebar-nav-title-color;
//             text-transform: uppercase;
//             text-align: left;

//             //    &::before {
//             //        height: 40px;
//             //    }
//         }
//     }
// }

// .app-header.navbar {
//     background-color: $navbar-brand-bg;
//     border: solid 1px #111111;
//     position: -webkit-sticky;
//     position: sticky;
//     top: 0px;

//     .navbar-brand {
//         background-position-x: center;
//         background-position-y: 38%;
//     }
// }

// .navbar-nav .nav-link {
//     color: $color-gray-md;

//     &:hover {
//         color: $color-gray-lt;
//     }
// }

.modal {
    .modal-content {
        // border: none;
        // background: transparent;
        border-radius: 10px;

        .modal-header {
            background: $rendr-wave-3;
            border-radius: 10px 10px 0 0;
            border-bottom: none;

            .close {
                display: none;
            }
        }

        .modal-body {
            color: black;
            padding: 0;

            border-left: 1px solid $color-black-25;
            border-right: 1px solid $color-black-25;

            .container {
                padding: 25px;
            }
        }

        .modal-footer {
            border-radius: 0 0 10px 10px;
            background: $color-panel-bg;
            border-top: none;
        }
    }
}

.modal-fullscreen {
    margin: 0;
    min-width: 100%;
    min-height: 100%;

    .modal-content {
        bottom: 0;
        top: 0;
        position: absolute;
        border-radius: 0;
        border: 0;

        .modal-header {
            border-radius: 0;

            .close {
                display: initial;
            }
        }

        .modal-body {
            border: 0;
            height: calc(100% - 58px); // 58px is height of .modal-header
        }
    }
}

// .app-footer {
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     height: 35px;
//     background: #1d262b;
//     color: #fff;
//     margin-left: 0 !important;
//     z-index: 1001;
//     border-top: 1px solid $color-gray-dk;
// }

// footer {
//     //height: 120px;
//     background: $color-gray-dk;
//     color: $color-gray-md;
//     text-align: center;

//     .row {
//         padding: 60px 0;
//     }

//     .mmt-logo {
//         height: 30px;
//     }
// }

// stuff we added due to migration to material ui
.pull-right {
    float: right;
}

.study-state {
    color: $electro-tek-orange;
    margin-right: 20px;
}

.input-error-msg {
    color: #ff4d4f;
    display: inline-block;
    font-size: smaller;
    padding: 4px;
    margin-top: 4px;
}