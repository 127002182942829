// Graph
$graph-panel-z-index: 0;
$grid-lines-z-index: 0;
$scrubber-z-index: 5;
$trace-lines-z-index: 10;
$play-cursor-z-index: 20;
$events-z-index: 30;
$legend-z-index: 40;
$video-z-index: 50;
$graph-control-z-index: 55;
$message-box-z-index: 60;
$create-event-mode-border-z-index: 70;
$channel-menu-z-index: 80;
$noneeg-overlay-z-index: 90;
$diagnostics-z-index: 90;
$graph-dropdowns-z-index: 100;
$graph-speed-slider-z-index: 100;
$toast-z-index: 2000;

// App shell
$sidebar-z-index: 1000;
// app-header is 1020
// modals are 1050
$import-study-page-z-index: 1030;
