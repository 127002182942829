@import "../../scss/z-index";
@import "../../scss/mmt/mobilemedtek_colors";

.video-player {
    video::-webkit-media-controls {
        display: none;
    }

    .video-container {
        $base-video-z-index: 1;
        $up-next-video-z-index: $base-video-z-index + 2;
        $currently-playing-video-z-index: $up-next-video-z-index + 2;
        $video-cover-z-index: $currently-playing-video-z-index + 10;
        $button-container-z-index: $video-cover-z-index + 1;

        &.hidden {
            display: none;
        }

        position: absolute;
        transition: 0.25s ease-in-out;
        z-index: $video-z-index;

        .video,
        .video-cover {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .video {
            background: $color-graph;
            position: absolute;

            &.current {
                z-index: $currently-playing-video-z-index;
            }
            &.up-next {
                z-index: $up-next-video-z-index;
                display: none;
            }
        }

        .video-cover {
            font-size: 20px;
            z-index: $video-cover-z-index;
            background: rgba(52, 105, 173, 0.95);

            &.error {
                background: rgba(219, 79, 61, 0.95);
            }

            .message {
                position: absolute;
                white-space: nowrap;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .video-cover-ended {
            color: yellow;
            display: flex;
            font-size: 20px;
            font-variant: small-caps;
            height: 100%;
            justify-content: center;
            position: absolute;
            overflow: hidden;
            width: 100%;
            z-index: $video-cover-z-index;

            .message {
                align-self: center;
                background: rgba(0, 0, 0, 0.75);
                line-height: 1em;
                padding: 100%;
                text-align: center;
                white-space: nowrap;
                z-index: $video-cover-z-index;
            }
        }

        .button-container {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: $button-container-z-index;

            button {
                padding: 0;
                z-index: 11;

                .icon {
                    font-size: 20px;
                }

                .pip-icon {
                    position: relative;
                    right: 40px;
                    font-size: 16px;

                    &.left {
                        left: 40px;
                    }
                }
            }

            .top-left {
                position: absolute;
                top: 5px;
                left: 5px;
            }

            .top-right {
                position: absolute;
                top: 5px;
                right: 5px;
            }

            .bottom-left {
                position: absolute;
                bottom: 5px;
                left: 5px;
            }

            .bottom-right {
                position: absolute;
                bottom: 5px;
                right: 5px;
            }
        }

        &.video-container-normal {
            width: 320px;
            height: 240px;
        }

        &.video-container-large {
            width: 560px;
            height: 420px;
        }

        &.video-container-bottom-right {
            bottom: 130px;
            right: 40px;

            &.minimized {
                bottom: 90px;
            }
        }

        &.video-container-bottom-left {
            bottom: 130px;
            left: 80px;

            &.minimized {
                bottom: 90px;
            }
        }

        &.video-container-top-right {
            top: 42px;
            right: 40px;
        }

        &.video-container-top-left {
            top: 42px;
            left: 80px;
        }
    }
}
