.montage-channel-list-view {
    @mixin action-icon {
        .action-icon {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    
    $list-view-width: 200px;
    $border: 1px solid rgb(34, 55, 69);
    user-select: none;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: $border;
    width: $list-view-width;
    min-width: $list-view-width;
    overflow: hidden;
    height: fit-content;

    .input-wrapper {
        border-bottom: $border;

        &.no-channels {
            border-bottom: 0;
        }
    }

    .action-icon {
        display: none;
        cursor: pointer;

        &.always-show {
            display: inline-block;
        }
    }

    .grab-handle, .action-icon, .new-divider {
        padding: 5px 10px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 90%;
    }

    .new-divider {
        cursor: pointer;
    }

    .grab-handle {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    .channel-formula {
        padding: 5px 10px;
        margin: 0;
        cursor: pointer;
        width: calc(#{$list-view-width} - 100px);
        display: inline-block;

        &:focus {
            outline: none;
            background: rgba(255, 255, 255, 0.6);
            color: rgba(0, 0, 0, 0.75);
        }
    }

    input {
        outline: 0;
        border: 0;
        background: transparent;
        color: #fff;

        &.new-channel {
            width: calc(#{$list-view-width} - 36px - 32px);
        }
    }

    .list-view {
        overflow-y: auto;

        .list-item {
            transition: 100ms background ease-in-out;
            border-bottom: $border;
            position: relative;

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.1);
                @include action-icon;
            }

            &.selected {
                background: rgba(0, 0, 0, 0.25);
            }
        }

        .group {
            height: 24px;
            background: rgba(0, 0, 0, 0.25);
            border-bottom: $border;
            position: relative;

            cursor: grab;

            &:active {
                cursor: grabbing;
            }

            &:hover {
                @include action-icon;
            }

            &:last-child {
                border-bottom: 0;
            }

            .grab-handle, .new-divider {
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }

            &.no-drag {
                cursor: default;
            }
        }
    }
}
