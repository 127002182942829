@import '../../scss/z-index.scss';
@import '../../scss/mmt/mobilemedtek_colors';


.import-elapsed {
    &.button {
        text-align: center;
        padding-bottom: 10px;
    }
    .label {
        color: lightgray;
    }
    .data {
        padding-left: 8px;
        padding-right: 2px;
        //font-weight: 800;
    }
}

.import-study {
    position: fixed;
    top: 0;
    bottom: 35px;
    left: 0;
    right: 0;
    z-index: $import-study-page-z-index;
    background: $rendr-wave-2;
    background-image: radial-gradient(
        farthest-corner at 0px 0px,
        rgba(#164158, 0.8) 0%,
        rgba(#2b96cc, 0.8) 50%,
        rgba(#2b96cc, 0.8) 60%,
        rgba(#164158, 0.8) 100%
    );
    background-attachment: fixed;
    overflow-y: auto;

    .container {
        width: 95%;
        max-width: 1200px;
        padding: 30px 50px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        .progress-container,
        .study-patient-verify {
            text-align: left;
        }

        .progress-container {
            .progress {
                margin-bottom: 20px;

                &.no-transition {
                    .progress-bar {
                        transition: none !important;
                    }
                }
            }

            .center {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .folder-selection-feedback {
            width: 100%;
            max-width: 550px;
            text-align: left;
            margin: auto;
            margin-bottom: 20px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            
            .category {
                padding: 15px 25px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                
                &:last-child {
                    border-bottom: 0;
                }
                
                .icon {
                    font-size: 125%;
                    position: relative;
                    top: 2px;
                    padding: 15px 0;
                }
                
                .icon-left {
                    margin-right: 10px;
                }

                .name {
                    font-size: 125%;
                }

                p {
                    color: rgba(255, 255, 255, 0.65);
                    margin: 0 32px 0 32px;

                    &.text-danger {
                        font-weight: bolder;
                    }
                    
                    span {
                        line-height: 130%;
                    }
                }
            }
        }

        .study-patient-verify {
            h4 {
                text-align: center;
            }

            p {
                margin-bottom: 5px;
            }

            select {
                width: 100%;
                max-width: 300px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px;
                margin-bottom: 10px;
            }

            .danger {
                background: rgba(255, 255, 255, 0.5);
                color: red;
                font-weight: bolder;
                padding: 2px 8px;
                border-radius: 4px;
            }
        }
    }

    .upload-feedback-labels {
        color: lightgray;
        padding-right: 10px;
    }
    
    .upload-feedback-values {
        font-weight: bold;
    }

    .chart-container {
        background-color: $rendr-wave-3;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 30px;
        padding-left: 10px;
        width: 99%;
    }

    .chart-tooltip {
        border-radius: 0.25rem;
        background: #26313c;
        color: #fff;
        padding: 1rem;
        box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
        text-align: center;

        .speed {
            font-size: 18px;
        }
        .date {
            font-size: 12px;
        }
    }

}
