@import "../../../scss/z-index";

.diagnostics {
    border: 1px solid rgba(255, 255, 255,  0.75);
    width: 800px;
    z-index: $diagnostics-z-index;
    position: relative;
    top: 20px;
    left: 100px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;

    .close-icon {
        float: right;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.6);

        &:hover {
            color: #fff;
        }
    }

    h3 {
        font-size: 16px;

        .record-icon-container {
            display: inline-block;
            position: relative;
            top: 3px;
            width: 24px;
            margin-right: 10px;
            height: 16px;

            .record-icon {
                cursor: pointer;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: color 500ms;
        
                &.red {
                    color: red;
                }

                &.orange {
                    color: orange;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }
    }

    .events {
        overflow-y: auto;

        p:last-child {
            margin: 0;
        }

        table {
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
                margin: 0;
            }

            th, td {
                padding: 5px 10px;
                text-align: right;
                border-bottom: 1px solid #fff5;

                &:first-child {
                    text-align: left;
                }
            }

            tr:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}