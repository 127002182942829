@import "../../scss/mmt/mobilemedtek_colors";

.metric-block {
    a {
        text-decoration: none;
    }

    .metric-box {
        text-transform: uppercase;
        color: #fff;
        padding: 20px;

        &.blue {
            background-color: $rendr-wave-1;
        }

        &.darkblue {
            background-color: $rendr-wave-1;
        }

        &.green {
            background-color: $rendr-wave-3;
        }

        &.darkgreen {
            background-color: $rendr-wave-2;
        }

        &.orange {
            background-color: $electro-tek-orange;
        }

        .card-block {
            padding-bottom: 0;

            &::after {
                content: none;
            }
        }
    }

    .metric-value {
        font-weight: 400;
        font-size: 48px;
    }
}
