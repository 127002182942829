@import "../../../../scss/z-index";

.grid-lines {
    z-index: $grid-lines-z-index;
    position: absolute;

    div {
        position: absolute;
        margin: 0;
        padding: 0;
    }

    .timestamp {
        position: absolute;
        bottom: -20.5px;
        font-size: 14px;
        transform: translateX(-50%);
        white-space: nowrap;
        user-select: none;
    }
}
