$icomoon-font-family: "ElectroTek-Icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-electrotek-Celsius: "\e926";
$icon-electrotek-temperature2: "\e926";
$icon-electrotek-Fahrenheit: "\e925";
$icon-electrotek-temperature: "\e925";
$icon-electrotek-arrow-down-left: "\ea3f";
$icon-electrotek-arrow-down-right: "\ea3d";
$icon-electrotek-arrow-up-left: "\ea39";
$icon-electrotek-arrow-up-right: "\ea3b";
$icon-electrotek-bin: "\e927";
$icon-electrotek-trashcan: "\e927";
$icon-electrotek-remove: "\e927";
$icon-electrotek-delete: "\e927";
$icon-electrotek-recycle: "\e927";
$icon-electrotek-dispose: "\e927";
$icon-electrotek-calibrate-mode: "\e943";
$icon-electrotek-fullscreen: "\e92d";
$icon-electrotek-fullscreen-exit: "\e92c";
$icon-electrotek-lines: "\e940";
$icon-electrotek-list: "\e940";
$icon-electrotek-weather: "\e940";
$icon-electrotek-map: "\e94c";
$icon-electrotek-notch: "\e91d";
$icon-electrotek-notch2: "\e91e";
$icon-electrotek-notch3: "\e91f";
$icon-electrotek-omega: "\ea66";
$icon-electrotek-opt: "\e91b";
$icon-electrotek-opt2: "\e91c";
$icon-electrotek-refresh: "\ea2e";
$icon-electrotek-tab: "\ea45";
$icon-electrotek-Chin: "\e939";
$icon-electrotek-Nasal-Pressure: "\e93a";
$icon-electrotek-Snore: "\e93b";
$icon-electrotek-add-montage: "\e950";
$icon-electrotek-add-to-list: "\e600";
$icon-electrotek-amplifier: "\e949";
$icon-electrotek-amplifier-connected: "\e94a";
$icon-electrotek-amplifier-disconnected: "\e94b";
$icon-electrotek-archive: "\e664";
$icon-electrotek-arrow-down: "\f063";
$icon-electrotek-arrow-left: "\f060";
$icon-electrotek-arrow-right: "\f061";
$icon-electrotek-arrow-up: "\f062";
$icon-electrotek-blink: "\e9e7";
$icon-electrotek-blink2: "\e903";
$icon-electrotek-calendar: "\e60d";
$icon-electrotek-camera: "\e67a";
$icon-electrotek-caret-down: "\f0d7";
$icon-electrotek-caret-left: "\f0d9";
$icon-electrotek-caret-right: "\f0da";
$icon-electrotek-caret-up: "\f0d8";
$icon-electrotek-check: "\e67d";
$icon-electrotek-check2: "\f00c";
$icon-electrotek-checkbox-checked: "\e8ee";
$icon-electrotek-checkbox-unchecked: "\e8ef";
$icon-electrotek-checkerboard: "\e6cd";
$icon-electrotek-chevron-down: "\e621";
$icon-electrotek-chevron-down-circle: "\e62d";
$icon-electrotek-chevron-left: "\e622";
$icon-electrotek-chevron-left-circle: "\e62e";
$icon-electrotek-chevron-right: "\e623";
$icon-electrotek-chevron-right-circle: "\e62f";
$icon-electrotek-chevron-small-down: "\e624";
$icon-electrotek-chevron-small-left: "\e625";
$icon-electrotek-chevron-small-right: "\e626";
$icon-electrotek-chevron-small-up: "\e627";
$icon-electrotek-chevron-up: "\e62c";
$icon-electrotek-chevron-up-circle: "\e630";
$icon-electrotek-chew: "\e9e9";
$icon-electrotek-color-lens: "\e7f7";
$icon-electrotek-compose: "\e6ea";
$icon-electrotek-control-fast-backward: "\e602";
$icon-electrotek-control-fast-forward: "\e632";
$icon-electrotek-control-forward: "\e6ab";
$icon-electrotek-control-pause: "\e635";
$icon-electrotek-control-play: "\e636";
$icon-electrotek-control-record: "\e637";
$icon-electrotek-control-stop: "\e638";
$icon-electrotek-control-to-end: "\e634";
$icon-electrotek-control-to-start: "\e633";
$icon-electrotek-controls: "\e716";
$icon-electrotek-cross: "\f00d";
$icon-electrotek-cross-circle: "\e68d";
$icon-electrotek-cross-circle-fill: "\e67e";
$icon-electrotek-cross-square-fill: "\e719";
$icon-electrotek-cross2: "\e60a";
$icon-electrotek-cry: "\ea01";
$icon-electrotek-dot: "\e63a";
$icon-electrotek-dots-three-horizontal: "\e63b";
$icon-electrotek-dots-three-vertical: "\e63c";
$icon-electrotek-eeg: "\e606";
$icon-electrotek-error: "\e6eb";
$icon-electrotek-export: "\e6a0";
$icon-electrotek-eye-closed: "\e9d1";
$icon-electrotek-eye-open: "\e9ce";
$icon-electrotek-file-empty: "\e604";
$icon-electrotek-file-excel: "\eadd";
$icon-electrotek-file-pdf: "\eada";
$icon-electrotek-file-report: "\e605";
$icon-electrotek-file-word: "\eadc";
$icon-electrotek-folder-open-regular: "\e931";
$icon-electrotek-folder-open-solid: "\e932";
$icon-electrotek-forgot-password: "\e92f";
$icon-electrotek-frown: "\e9e5";
$icon-electrotek-grind: "\e9ff";
$icon-electrotek-heart: "\e6b4";
$icon-electrotek-heart-fill: "\e6b5";
$icon-electrotek-help-circle: "\e6b6";
$icon-electrotek-high-cpu: "\e94e";
$icon-electrotek-home: "\e6b8";
$icon-electrotek-impedance-mode: "\e944";
$icon-electrotek-import: "\e934";
$icon-electrotek-info: "\e6c0";
$icon-electrotek-info-circle: "\ea0c";
$icon-electrotek-info-circle-fill: "\e6bf";
$icon-electrotek-laugh: "\e9df";
$icon-electrotek-light: "\e901";
$icon-electrotek-line-graph: "\e6ce";
$icon-electrotek-lock: "\e6d4";
$icon-electrotek-lock-custom: "\e6d6";
$icon-electrotek-lock-open: "\e6d3";
$icon-electrotek-lock-recorded: "\e6d5";
$icon-electrotek-lockoverlay: "\e918";
$icon-electrotek-measure: "\e68a";
$icon-electrotek-merge: "\e6da";
$icon-electrotek-microchip-solid: "\e933";
$icon-electrotek-minus: "\e60b";
$icon-electrotek-minus-circle: "\e6e3";
$icon-electrotek-minus-circle-fill: "\e67f";
$icon-electrotek-minus-square-fill: "\e71a";
$icon-electrotek-monitoring: "\e93d";
$icon-electrotek-montage: "\e94f";
$icon-electrotek-move: "\e9b2";
$icon-electrotek-normal-mode: "\e945";
$icon-electrotek-note-quick: "\e6ec";
$icon-electrotek-notification: "\ea08";
$icon-electrotek-palette: "\e6f1";
$icon-electrotek-pencil: "\e6f3";
$icon-electrotek-person: "\e8e5";
$icon-electrotek-person-add: "\e8e6";
$icon-electrotek-pin: "\e6f6";
$icon-electrotek-plus: "\e6f7";
$icon-electrotek-plus-circle: "\e60c";
$icon-electrotek-plus-circle-fill: "\e680";
$icon-electrotek-plus-square-fill: "\e71b";
$icon-electrotek-power-plug: "\e6f9";
$icon-electrotek-print: "\e6fc";
$icon-electrotek-radio-button-off: "\e8f0";
$icon-electrotek-radio-button-on: "\e8f1";
$icon-electrotek-ruler: "\e70a";
$icon-electrotek-search: "\e6d9";
$icon-electrotek-settings: "\e687";
$icon-electrotek-share-alt-solid: "\e930";
$icon-electrotek-signal-strength: "\e957";
$icon-electrotek-smile: "\e902";
$icon-electrotek-speedometer2: "\e938";
$icon-electrotek-spinner: "\e603";
$icon-electrotek-split: "\e6dd";
$icon-electrotek-splitscreen: "\e900";
$icon-electrotek-stopwatch: "\e71e";
$icon-electrotek-swallow: "\e608";
$icon-electrotek-swallow2: "\e904";
$icon-electrotek-talk: "\e607";
$icon-electrotek-tile: "\e93e";
$icon-electrotek-tools: "\e72e";
$icon-electrotek-videocam: "\e6cf";
$icon-electrotek-videocam-off: "\e6d0";
$icon-electrotek-warning: "\ea07";
$icon-electrotek-warning-fill: "\e60e";
$icon-electrotek-weak-bluetooth: "\e94d";
$icon-electrotek-wifi: "\e92e";
$icon-electrotek-wrench: "\e601";
$icon-electrotek-yawn: "\e9fd";
$icon-electrotek-zoom-in: "\e987";
$icon-electrotek-zoom-out: "\e988";
$icon-electrotek-battery-0: "\e910";
$icon-electrotek-battery-02: "\e915";
$icon-electrotek-battery-1: "\e90f";
$icon-electrotek-battery-12: "\e914";
$icon-electrotek-battery-2: "\e90e";
$icon-electrotek-battery-22: "\e913";
$icon-electrotek-battery-3: "\e90d";
$icon-electrotek-battery-32: "\e912";
$icon-electrotek-battery-4: "\e90c";
$icon-electrotek-battery-42: "\e911";
$icon-electrotek-bolt: "\e90b";
$icon-electrotek-power-off: "\e90a";
$icon-electrotek-star: "\e905";
$icon-electrotek-star-o: "\e906";
$icon-electrotek-th-list: "\e941";
$icon-electrotek-thumb-press: "\e909";
$icon-electrotek-thumbs-up: "\e907";
$icon-electrotek-thumbs-up2: "\e908";
$icon-electrotek-user-circle: "\f2bd";
$icon-electrotek-user-circle-o: "\f2be";
$icon-electrotek-bluetooth: "\e936";
$icon-electrotek-bluetooth-disconnected: "\e935";
$icon-electrotek-flag: "\e921";
$icon-electrotek-health: "\e67b";
$icon-electrotek-menu: "\e942";
$icon-electrotek-note_add: "\e916";
$icon-electrotek-sd_storage: "\e93f";
$icon-electrotek-sd_card: "\e93f";
$icon-electrotek-speedometer: "\e937";
$icon-electrotek-straighten: "\e917";
$icon-electrotek-usb: "\e93c";
$icon-electrotek-cloud-complete: "\e920";
$icon-electrotek-cloud-disabled: "\e956";
$icon-electrotek-cloud-offline: "\e958";
$icon-electrotek-cloud-pending: "\e959";
$icon-electrotek-cloud-uploading: "\e95a";
$icon-electrotek-external-event: "\e952";
$icon-electrotek-hardware: "\e955";
$icon-electrotek-low-battery: "\e951";
$icon-electrotek-ok-battery: "\e953";
$icon-electrotek-software-recovery: "\e954";
$icon-electrotek-control-faster-backward: "\e928";
$icon-electrotek-control-faster-forward: "\e929";
$icon-electrotek-control-fastest-backward: "\e92a";
$icon-electrotek-control-fastest-forward: "\e92b";
$icon-electrotek-retrieve-data: "\e946";
$icon-electrotek-sync-data: "\e947";
$icon-electrotek-wait-and-upload: "\e948";
$icon-electrotek-lungs: "\e924";
$icon-electrotek-lungs-fill: "\e919";
$icon-electrotek-thermometer-three-quarters: "\e923";
$icon-electrotek-wind: "\e922";
$icon-electrotek-wind2: "\e91a";

