@import "../../../scss/mmt/mobilemedtek_colors";
@import "../../../scss/z-index.scss";

.options-menu{
    background-color: #eeeeee;
    z-index: $graph-dropdowns-z-index;

    .filter-panel {
        min-width: 0;
    }

    .row {
        margin: 0;
    }

    .col,
    .col-md-2 {
        padding: 0;
    }

    .options-subpanel {
        text-align: right;
        -ms-flex: 0 0 292px;
        flex: 0 0 292px;

        .active {
            color: white !important;
            background-color: $rendr-wave-2 !important;

            &:hover {
                background-color: $rendr-wave-3 !important;
            }

            .icon {
                background: transparent !important;
            }
        }

        .btn-icon {
            color: $rendr-wave-2;
            padding: 0;
            height: 100%;
            width: 50px;

            &:hover {
                color: $rendr-wave-3;
                background-color: $color-black-10;
            }

            [class^='icon-']:before,
            [class*=' icon-']:before {
                margin: 0;
            }

            .icon {
                font-size: 24px;
            }

            .icon-smaller {
                font-size: 20px;
                margin-top: 3px;
            }
        }
    }

    .btn-info:hover,
    .btn-info:focus,
    .btn-info:active,
    .btn-info.active,
    .open > .dropdown-toggle.btn-info {
        background-color: $color-white;
    }
}

.more-options {
    position: absolute;
    top: 60px;
    right: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    visibility: collapse;
    transition: visibility 300ms;
    z-index: $graph-dropdowns-z-index + 1;

    li {
        opacity: 0;
        transition: opacity 200ms, transform 200ms;
        transition-timing-function: ease-in;

        .icon-electrotek-measure {
            transform: rotate(-90deg);
        }

        &:nth-child(1) {
            transition-delay: 0ms;
        }
        &:nth-child(2) {
            transition-delay: 25ms;
        }
        &:nth-child(3) {
            transition-delay: 50ms;
        }
        &:nth-child(4) {
            transition-delay: 75ms;
        }
        &:nth-child(5) {
            transition-delay: 100ms;
        }
    }

    &.show {
        visibility: visible;
        transition: visibility;
    }

    &.show li {
        opacity: 1;
        transform: scale(1);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;

        &:nth-child(1) {
            transition-delay: 0ms;
        }
        &:nth-child(2) {
            transition-delay: 50ms;
        }
        &:nth-child(3) {
            transition-delay: 100ms;
        }
        &:nth-child(4) {
            transition-delay: 150ms;
        }
        &:nth-child(5) {
            transition-delay: 200ms;
        }
    }

    li button {
        border: none;
        display: block;
        color: $color-white;
        background-color: $rendr-wave-2;
        text-align: center;
        margin-bottom: 10px;
        padding: 0;
        width: 50px;
        height: 50px;
        border-radius: 25px;

        &:before {
            display: inline-block;
        }

        &:focus {
            background-color: $rendr-wave-3;
            outline: none;
        }

        &:hover {
            background-color: $rendr-wave-3;
        }

        .icon {
            font-size: 24px;
        }

        &:disabled {
            background-color: $rendr-wave-2;
            opacity: 0.85;
            color: white;
            cursor: not-allowed;

            &:hover {
                background-color: $rendr-wave-3;
                color: white;
            }
        }
    }
}
