.dashboard {
    td, th {
        border-bottom: none;
    }

    td {
        color: rgba(255, 255, 255, 0.75);

        .icon {
            text-decoration: none;
        }
    }
}
