@import "../../scss/mmt/mobilemedtek_colors";

.patient-audit-logs {
    padding-top: 20px;

    .accordion__button {
        cursor: pointer;
        padding: 20px;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
        background-color: $rendr-wave-3;
        border-radius: 0.25rem;
        outline: none;

        &:before {
            transition: transform 0.1s;
            display: inline-block;
            content: '';
            height: 10px;
            width: 10px;
            margin-right: 16px;
            border-bottom: 2px solid currentColor;
            border-right: 2px solid currentColor;
            transform: rotate(-45deg);
            position: relative;
            top: -2px;
        }
        
        &[aria-expanded='true']::before,
        &[aria-selected='true']::before { 
            transition: transform 0.1s;
            transform: rotate(45deg);
        }
    }

    .accordion__panel {
        padding-top: 5px;
    }
}