.channel-labels {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    font-size: 14px;
}

.channel-label {
    position: absolute;
    right: 5px;
    text-align: end;
    vertical-align: middle;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 1.5px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background: #0095a6;
        border-radius: 10px 10px 10px 10px;
    }
}

.channel-label.noneeg {
    font-style: italic;
}
