@import "../../scss/mmt/mobilemedtek_colors";

.log-entry-detail {
    background-image: none;
    background-color: rgba(0,0,0,.2);

    .log-entry-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color:  $rendr-wave-2;
    }

    .stack-trace {
        background: darkgrey;
        border-radius: 5px;
        padding: 8px;
    }
}
