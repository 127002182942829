@import "../../../../../scss/z-index.scss";
@import "../../../../../scss/mmt/mobilemedtek_colors.scss";

.non-eeg-overlay {
    $height: 32px;
    $dropdown-width: 200px;

    visibility: collapse;
    z-index: $noneeg-overlay-z-index;
    position: absolute;
    top: 5px;
    right: 45px;
    height: $height;

    background-color: #eeeeee;

    &.show {
        visibility: visible;
        transition: visibility;
        transition-delay: 0s;
    }

    .channel-type-icon {
        display: inline-block;
        border-right: 1px solid $color-gray-dv;
        background-color: #eeeeee;
        cursor: default;
        height: $height;

        .icon {
            font-size: 25px !important;
        }
    }

    .channel-type-icon,
    .exit-button {
        padding: 0 5px;
        color: $electro-tek-dark-gray;

        .icon {
            position: relative;
            top: 4px;
        }
    }

    .low-filter-dropdown,
    .high-filter-dropdown,
    .sensitivity-dropdown {
        display: inline-block;
        width: $dropdown-width;
    }

    .low-filter-dropdown,
    .high-filter-dropdown {
        border-right: 1px solid $color-gray-dv;
    }

    .exit-button {
        display: inline-block;
        background-color: #eeeeee;
        border-left: 1px solid $color-gray-dv;
        height: $height;
        cursor: pointer;

        .icon {
            padding: 2px 0 0;
            font-size: 25px !important;
        }

        &:hover {
            background: $color-white;
        }
    }

    .graph-settings-dropdown {
        .dropdown {
            width: $dropdown-width;
            height: $height;

            .dropdown-toggle {
                height: 100%;
                padding: 5px 8px;

                .tag,
                .value {
                    display: inline-block;
                }

                .value {
                    margin-left: 10px;
                }

                .icon {
                    top: 10px;
                }
            }
        }
    }
}
