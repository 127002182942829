@mixin head-electrodes {
    .electrode {
        $background-color: #222;
        border: 1px solid $background-color;
        border-radius: 50%;
        background-color: rgba(40, 49, 85, 0.7);
        position: relative;

        .electrode-label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 13px;
        }

        .custom-electrode-label {
            position: absolute;
            top: 95%;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
        }

        &.Fp1 {
            border: 1px solid $primary;
        }

        &.Fp2 {
            border: 1px solid $primary;
        }

        &.F7 {
            border: 1px solid $danger;
        }

        &.F8 {
            border: 1px solid $danger;
        }

        &.F3 {
            border: 1px solid $primary;
        }

        &.F4 {
            border: 1px solid $primary;
        }

        &.Fz {
            border: 1px solid $warning;
        }

        &.A1 {
            border: 1px solid $danger;
        }

        &.A2 {
            border: 1px solid $danger;
        }

        &.T3 {
            border: 1px solid $danger;
        }

        &.T4 {
            border: 1px solid $danger;
        }

        &.C3 {
            border: 1px solid $primary;
        }

        &.C4 {
            border: 1px solid $primary;
        }

        &.Cz {
            border: 1px solid $warning;
        }

        &.T5 {
            border: 1px solid $danger;
        }

        &.T6 {
            border: 1px solid $danger;
        }

        &.P3 {
            border: 1px solid $primary;
        }

        &.P4 {
            border: 1px solid $primary;
        }

        &.Pz {
            border: 1px solid $warning;
        }

        &.O1 {
            border: 1px solid $primary;
        }

        &.O2 {
            border: 1px solid $primary;
        }

        &.Oz {
            border: 1px solid $primary;
        }
    }
}
