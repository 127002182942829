@import "../../scss/mmt/mobilemedtek_colors";

.password-input {
    display: flex;
    width: 100%;
    $border-color: #ddd;

    input {
        background: #fff !important;
        border-color: $border-color;
        border-right: 0;
        order: 0;
        justify-content: center;
        width: calc(100% - 30px);
        display: inline-block;
        -webkit-appearance: none;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }

    .show-password-btn {
        background: #fff;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        order: 1;
        justify-content: center;
        font-size: 20px;
        padding-top: 7px;
        padding-bottom: 6px;
        width: 30px;
        cursor: pointer;
        color: $electro-tek-light-gray;

        &:hover {
            color: $electro-tek-gray;
        }
    }
}
