@import '../../scss/mmt/mobilemedtek_colors';

.studies-list-row {
    &.studylist-border-left {
        border-left: 20px solid rgba($rendr-wave-3, 0.4);
        border-top: transparent;
        border-bottom: transparent;
    }
}



.extra-info {
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;

    .live-study-indicator {
        background-color: OrangeRed;
        border-radius: 5px;
        color: White;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        padding: 0px 5px 1px 3px;
    }

    .info-item {
        font-size: 14px;
        display: inline-block;

        &.sync-error {
            background-color: rgba(139,0,0,.75);
            border-radius: 5px;
            color: White;
            width: 80px;
            padding-left: 7px;
        }

        &:after {
            content: '|';
            margin: 0 15px;
            position: relative;
            top: -1px;
        }

        &:last-child:after {
            content: '';
        }

        .progress {
            $height: 15px;
            display: inline-block;
            margin: 0;
            margin-left: 10px;
            width: 100px;
            height: $height;
            border-radius: 3px;
            vertical-align: middle;
            position: relative;
            top: -1px;

            &.danger {
                border: 1px solid #f86c6b;

                .progress-bar {
                    background: #f86c6b;
                }
            }

            &.success {
                border: 1px solid #4dbd74;

                .progress-bar {
                    background: #4dbd74;
                }
            }

            .progress-bar {
                height: $height - 2px;
            }
        }
    }
}

