.facilities {
    .name,
    .domain,
    .date-created {
        white-space: nowrap;
    }

    .name {
        text-overflow: ellipsis;
        max-width: 300px;
        overflow: hidden;
    }
}

.facility-form {
    background-color: rgba(28, 56, 70, 0.2);
    border-radius: 8px;
    margin: 0 auto 50px auto;
    padding: 20px 20px 60px 20px;
    width: 95%;

    .MuiFormControl-root {
        margin-bottom: 24px;
        background-color: rgba(255, 255, 255, 0.05);
    }

    .selector-parent {
        div:first-of-type {
                width: 100%
        }
    }

    .cancel-btn {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.7);
        margin-right: 8px;
    }
}