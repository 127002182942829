@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?js9cnq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?js9cnq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?js9cnq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?js9cnq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?js9cnq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-electrotek-"], [class*=" icon-electrotek-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-electrotek-Celsius {
  &:before {
    content: $icon-electrotek-Celsius; 
  }
}
.icon-electrotek-temperature2 {
  &:before {
    content: $icon-electrotek-temperature2; 
  }
}
.icon-electrotek-Fahrenheit {
  &:before {
    content: $icon-electrotek-Fahrenheit; 
  }
}
.icon-electrotek-temperature {
  &:before {
    content: $icon-electrotek-temperature; 
  }
}
.icon-electrotek-arrow-down-left {
  &:before {
    content: $icon-electrotek-arrow-down-left; 
  }
}
.icon-electrotek-arrow-down-right {
  &:before {
    content: $icon-electrotek-arrow-down-right; 
  }
}
.icon-electrotek-arrow-up-left {
  &:before {
    content: $icon-electrotek-arrow-up-left; 
  }
}
.icon-electrotek-arrow-up-right {
  &:before {
    content: $icon-electrotek-arrow-up-right; 
  }
}
.icon-electrotek-bin {
  &:before {
    content: $icon-electrotek-bin; 
  }
}
.icon-electrotek-trashcan {
  &:before {
    content: $icon-electrotek-trashcan; 
  }
}
.icon-electrotek-remove {
  &:before {
    content: $icon-electrotek-remove; 
  }
}
.icon-electrotek-delete {
  &:before {
    content: $icon-electrotek-delete; 
  }
}
.icon-electrotek-recycle {
  &:before {
    content: $icon-electrotek-recycle; 
  }
}
.icon-electrotek-dispose {
  &:before {
    content: $icon-electrotek-dispose; 
  }
}
.icon-electrotek-calibrate-mode {
  &:before {
    content: $icon-electrotek-calibrate-mode; 
  }
}
.icon-electrotek-fullscreen {
  &:before {
    content: $icon-electrotek-fullscreen; 
  }
}
.icon-electrotek-fullscreen-exit {
  &:before {
    content: $icon-electrotek-fullscreen-exit; 
  }
}
.icon-electrotek-lines {
  &:before {
    content: $icon-electrotek-lines; 
  }
}
.icon-electrotek-list {
  &:before {
    content: $icon-electrotek-list; 
  }
}
.icon-electrotek-weather {
  &:before {
    content: $icon-electrotek-weather; 
  }
}
.icon-electrotek-map {
  &:before {
    content: $icon-electrotek-map; 
  }
}
.icon-electrotek-notch {
  &:before {
    content: $icon-electrotek-notch; 
  }
}
.icon-electrotek-notch2 {
  &:before {
    content: $icon-electrotek-notch2; 
  }
}
.icon-electrotek-notch3 {
  &:before {
    content: $icon-electrotek-notch3; 
  }
}
.icon-electrotek-omega {
  &:before {
    content: $icon-electrotek-omega; 
  }
}
.icon-electrotek-opt {
  &:before {
    content: $icon-electrotek-opt; 
  }
}
.icon-electrotek-opt2 {
  &:before {
    content: $icon-electrotek-opt2; 
  }
}
.icon-electrotek-refresh {
  &:before {
    content: $icon-electrotek-refresh; 
  }
}
.icon-electrotek-tab {
  &:before {
    content: $icon-electrotek-tab; 
  }
}
.icon-electrotek-Chin {
  &:before {
    content: $icon-electrotek-Chin; 
  }
}
.icon-electrotek-Nasal-Pressure {
  &:before {
    content: $icon-electrotek-Nasal-Pressure; 
  }
}
.icon-electrotek-Snore {
  &:before {
    content: $icon-electrotek-Snore; 
  }
}
.icon-electrotek-add-montage {
  &:before {
    content: $icon-electrotek-add-montage; 
  }
}
.icon-electrotek-add-to-list {
  &:before {
    content: $icon-electrotek-add-to-list; 
  }
}
.icon-electrotek-amplifier {
  &:before {
    content: $icon-electrotek-amplifier; 
  }
}
.icon-electrotek-amplifier-connected {
  &:before {
    content: $icon-electrotek-amplifier-connected; 
  }
}
.icon-electrotek-amplifier-disconnected {
  &:before {
    content: $icon-electrotek-amplifier-disconnected; 
  }
}
.icon-electrotek-archive {
  &:before {
    content: $icon-electrotek-archive; 
  }
}
.icon-electrotek-arrow-down {
  &:before {
    content: $icon-electrotek-arrow-down; 
  }
}
.icon-electrotek-arrow-left {
  &:before {
    content: $icon-electrotek-arrow-left; 
  }
}
.icon-electrotek-arrow-right {
  &:before {
    content: $icon-electrotek-arrow-right; 
  }
}
.icon-electrotek-arrow-up {
  &:before {
    content: $icon-electrotek-arrow-up; 
  }
}
.icon-electrotek-blink {
  &:before {
    content: $icon-electrotek-blink; 
  }
}
.icon-electrotek-blink2 {
  &:before {
    content: $icon-electrotek-blink2; 
  }
}
.icon-electrotek-calendar {
  &:before {
    content: $icon-electrotek-calendar; 
  }
}
.icon-electrotek-camera {
  &:before {
    content: $icon-electrotek-camera; 
  }
}
.icon-electrotek-caret-down {
  &:before {
    content: $icon-electrotek-caret-down; 
  }
}
.icon-electrotek-caret-left {
  &:before {
    content: $icon-electrotek-caret-left; 
  }
}
.icon-electrotek-caret-right {
  &:before {
    content: $icon-electrotek-caret-right; 
  }
}
.icon-electrotek-caret-up {
  &:before {
    content: $icon-electrotek-caret-up; 
  }
}
.icon-electrotek-check {
  &:before {
    content: $icon-electrotek-check; 
  }
}
.icon-electrotek-check2 {
  &:before {
    content: $icon-electrotek-check2; 
  }
}
.icon-electrotek-checkbox-checked {
  &:before {
    content: $icon-electrotek-checkbox-checked; 
  }
}
.icon-electrotek-checkbox-unchecked {
  &:before {
    content: $icon-electrotek-checkbox-unchecked; 
  }
}
.icon-electrotek-checkerboard {
  &:before {
    content: $icon-electrotek-checkerboard; 
  }
}
.icon-electrotek-chevron-down {
  &:before {
    content: $icon-electrotek-chevron-down; 
  }
}
.icon-electrotek-chevron-down-circle {
  &:before {
    content: $icon-electrotek-chevron-down-circle; 
  }
}
.icon-electrotek-chevron-left {
  &:before {
    content: $icon-electrotek-chevron-left; 
  }
}
.icon-electrotek-chevron-left-circle {
  &:before {
    content: $icon-electrotek-chevron-left-circle; 
  }
}
.icon-electrotek-chevron-right {
  &:before {
    content: $icon-electrotek-chevron-right; 
  }
}
.icon-electrotek-chevron-right-circle {
  &:before {
    content: $icon-electrotek-chevron-right-circle; 
  }
}
.icon-electrotek-chevron-small-down {
  &:before {
    content: $icon-electrotek-chevron-small-down; 
  }
}
.icon-electrotek-chevron-small-left {
  &:before {
    content: $icon-electrotek-chevron-small-left; 
  }
}
.icon-electrotek-chevron-small-right {
  &:before {
    content: $icon-electrotek-chevron-small-right; 
  }
}
.icon-electrotek-chevron-small-up {
  &:before {
    content: $icon-electrotek-chevron-small-up; 
  }
}
.icon-electrotek-chevron-up {
  &:before {
    content: $icon-electrotek-chevron-up; 
  }
}
.icon-electrotek-chevron-up-circle {
  &:before {
    content: $icon-electrotek-chevron-up-circle; 
  }
}
.icon-electrotek-chew {
  &:before {
    content: $icon-electrotek-chew; 
  }
}
.icon-electrotek-color-lens {
  &:before {
    content: $icon-electrotek-color-lens; 
  }
}
.icon-electrotek-compose {
  &:before {
    content: $icon-electrotek-compose; 
  }
}
.icon-electrotek-control-fast-backward {
  &:before {
    content: $icon-electrotek-control-fast-backward; 
  }
}
.icon-electrotek-control-fast-forward {
  &:before {
    content: $icon-electrotek-control-fast-forward; 
  }
}
.icon-electrotek-control-forward {
  &:before {
    content: $icon-electrotek-control-forward; 
  }
}
.icon-electrotek-control-pause {
  &:before {
    content: $icon-electrotek-control-pause; 
  }
}
.icon-electrotek-control-play {
  &:before {
    content: $icon-electrotek-control-play; 
  }
}
.icon-electrotek-control-record {
  &:before {
    content: $icon-electrotek-control-record; 
  }
}
.icon-electrotek-control-stop {
  &:before {
    content: $icon-electrotek-control-stop; 
  }
}
.icon-electrotek-control-to-end {
  &:before {
    content: $icon-electrotek-control-to-end; 
  }
}
.icon-electrotek-control-to-start {
  &:before {
    content: $icon-electrotek-control-to-start; 
  }
}
.icon-electrotek-controls {
  &:before {
    content: $icon-electrotek-controls; 
  }
}
.icon-electrotek-cross {
  &:before {
    content: $icon-electrotek-cross; 
  }
}
.icon-electrotek-cross-circle {
  &:before {
    content: $icon-electrotek-cross-circle; 
  }
}
.icon-electrotek-cross-circle-fill {
  &:before {
    content: $icon-electrotek-cross-circle-fill; 
  }
}
.icon-electrotek-cross-square-fill {
  &:before {
    content: $icon-electrotek-cross-square-fill; 
  }
}
.icon-electrotek-cross2 {
  &:before {
    content: $icon-electrotek-cross2; 
  }
}
.icon-electrotek-cry {
  &:before {
    content: $icon-electrotek-cry; 
  }
}
.icon-electrotek-dot {
  &:before {
    content: $icon-electrotek-dot; 
  }
}
.icon-electrotek-dots-three-horizontal {
  &:before {
    content: $icon-electrotek-dots-three-horizontal; 
  }
}
.icon-electrotek-dots-three-vertical {
  &:before {
    content: $icon-electrotek-dots-three-vertical; 
  }
}
.icon-electrotek-eeg {
  &:before {
    content: $icon-electrotek-eeg; 
  }
}
.icon-electrotek-error {
  &:before {
    content: $icon-electrotek-error; 
  }
}
.icon-electrotek-export {
  &:before {
    content: $icon-electrotek-export; 
  }
}
.icon-electrotek-eye-closed {
  &:before {
    content: $icon-electrotek-eye-closed; 
  }
}
.icon-electrotek-eye-open {
  &:before {
    content: $icon-electrotek-eye-open; 
  }
}
.icon-electrotek-file-empty {
  &:before {
    content: $icon-electrotek-file-empty; 
  }
}
.icon-electrotek-file-excel {
  &:before {
    content: $icon-electrotek-file-excel; 
  }
}
.icon-electrotek-file-pdf {
  &:before {
    content: $icon-electrotek-file-pdf; 
  }
}
.icon-electrotek-file-report {
  &:before {
    content: $icon-electrotek-file-report; 
  }
}
.icon-electrotek-file-word {
  &:before {
    content: $icon-electrotek-file-word; 
  }
}
.icon-electrotek-folder-open-regular {
  &:before {
    content: $icon-electrotek-folder-open-regular; 
  }
}
.icon-electrotek-folder-open-solid {
  &:before {
    content: $icon-electrotek-folder-open-solid; 
  }
}
.icon-electrotek-forgot-password {
  &:before {
    content: $icon-electrotek-forgot-password; 
  }
}
.icon-electrotek-frown {
  &:before {
    content: $icon-electrotek-frown; 
  }
}
.icon-electrotek-grind {
  &:before {
    content: $icon-electrotek-grind; 
  }
}
.icon-electrotek-heart {
  &:before {
    content: $icon-electrotek-heart; 
  }
}
.icon-electrotek-heart-fill {
  &:before {
    content: $icon-electrotek-heart-fill; 
  }
}
.icon-electrotek-help-circle {
  &:before {
    content: $icon-electrotek-help-circle; 
  }
}
.icon-electrotek-high-cpu {
  &:before {
    content: $icon-electrotek-high-cpu; 
  }
}
.icon-electrotek-home {
  &:before {
    content: $icon-electrotek-home; 
  }
}
.icon-electrotek-impedance-mode {
  &:before {
    content: $icon-electrotek-impedance-mode; 
  }
}
.icon-electrotek-import {
  &:before {
    content: $icon-electrotek-import; 
  }
}
.icon-electrotek-info {
  &:before {
    content: $icon-electrotek-info; 
  }
}
.icon-electrotek-info-circle {
  &:before {
    content: $icon-electrotek-info-circle; 
  }
}
.icon-electrotek-info-circle-fill {
  &:before {
    content: $icon-electrotek-info-circle-fill; 
  }
}
.icon-electrotek-laugh {
  &:before {
    content: $icon-electrotek-laugh; 
  }
}
.icon-electrotek-light {
  &:before {
    content: $icon-electrotek-light; 
  }
}
.icon-electrotek-line-graph {
  &:before {
    content: $icon-electrotek-line-graph; 
  }
}
.icon-electrotek-lock {
  &:before {
    content: $icon-electrotek-lock; 
  }
}
.icon-electrotek-lock-custom {
  &:before {
    content: $icon-electrotek-lock-custom; 
  }
}
.icon-electrotek-lock-open {
  &:before {
    content: $icon-electrotek-lock-open; 
  }
}
.icon-electrotek-lock-recorded {
  &:before {
    content: $icon-electrotek-lock-recorded; 
  }
}
.icon-electrotek-lockoverlay {
  &:before {
    content: $icon-electrotek-lockoverlay; 
  }
}
.icon-electrotek-measure {
  &:before {
    content: $icon-electrotek-measure; 
  }
}
.icon-electrotek-merge {
  &:before {
    content: $icon-electrotek-merge; 
  }
}
.icon-electrotek-microchip-solid {
  &:before {
    content: $icon-electrotek-microchip-solid; 
  }
}
.icon-electrotek-minus {
  &:before {
    content: $icon-electrotek-minus; 
  }
}
.icon-electrotek-minus-circle {
  &:before {
    content: $icon-electrotek-minus-circle; 
  }
}
.icon-electrotek-minus-circle-fill {
  &:before {
    content: $icon-electrotek-minus-circle-fill; 
  }
}
.icon-electrotek-minus-square-fill {
  &:before {
    content: $icon-electrotek-minus-square-fill; 
  }
}
.icon-electrotek-monitoring {
  &:before {
    content: $icon-electrotek-monitoring; 
  }
}
.icon-electrotek-montage {
  &:before {
    content: $icon-electrotek-montage; 
  }
}
.icon-electrotek-move {
  &:before {
    content: $icon-electrotek-move; 
  }
}
.icon-electrotek-normal-mode {
  &:before {
    content: $icon-electrotek-normal-mode; 
  }
}
.icon-electrotek-note-quick {
  &:before {
    content: $icon-electrotek-note-quick; 
  }
}
.icon-electrotek-notification {
  &:before {
    content: $icon-electrotek-notification; 
  }
}
.icon-electrotek-palette {
  &:before {
    content: $icon-electrotek-palette; 
  }
}
.icon-electrotek-pencil {
  &:before {
    content: $icon-electrotek-pencil; 
  }
}
.icon-electrotek-person {
  &:before {
    content: $icon-electrotek-person; 
  }
}
.icon-electrotek-person-add {
  &:before {
    content: $icon-electrotek-person-add; 
  }
}
.icon-electrotek-pin {
  &:before {
    content: $icon-electrotek-pin; 
  }
}
.icon-electrotek-plus {
  &:before {
    content: $icon-electrotek-plus; 
  }
}
.icon-electrotek-plus-circle {
  &:before {
    content: $icon-electrotek-plus-circle; 
  }
}
.icon-electrotek-plus-circle-fill {
  &:before {
    content: $icon-electrotek-plus-circle-fill; 
  }
}
.icon-electrotek-plus-square-fill {
  &:before {
    content: $icon-electrotek-plus-square-fill; 
  }
}
.icon-electrotek-power-plug {
  &:before {
    content: $icon-electrotek-power-plug; 
  }
}
.icon-electrotek-print {
  &:before {
    content: $icon-electrotek-print; 
  }
}
.icon-electrotek-radio-button-off {
  &:before {
    content: $icon-electrotek-radio-button-off; 
  }
}
.icon-electrotek-radio-button-on {
  &:before {
    content: $icon-electrotek-radio-button-on; 
  }
}
.icon-electrotek-ruler {
  &:before {
    content: $icon-electrotek-ruler; 
  }
}
.icon-electrotek-search {
  &:before {
    content: $icon-electrotek-search; 
  }
}
.icon-electrotek-settings {
  &:before {
    content: $icon-electrotek-settings; 
  }
}
.icon-electrotek-share-alt-solid {
  &:before {
    content: $icon-electrotek-share-alt-solid; 
  }
}
.icon-electrotek-signal-strength {
  &:before {
    content: $icon-electrotek-signal-strength; 
  }
}
.icon-electrotek-smile {
  &:before {
    content: $icon-electrotek-smile; 
  }
}
.icon-electrotek-speedometer2 {
  &:before {
    content: $icon-electrotek-speedometer2; 
  }
}
.icon-electrotek-spinner {
  &:before {
    content: $icon-electrotek-spinner; 
  }
}
.icon-electrotek-split {
  &:before {
    content: $icon-electrotek-split; 
  }
}
.icon-electrotek-splitscreen {
  &:before {
    content: $icon-electrotek-splitscreen; 
  }
}
.icon-electrotek-stopwatch {
  &:before {
    content: $icon-electrotek-stopwatch; 
  }
}
.icon-electrotek-swallow {
  &:before {
    content: $icon-electrotek-swallow; 
  }
}
.icon-electrotek-swallow2 {
  &:before {
    content: $icon-electrotek-swallow2; 
  }
}
.icon-electrotek-talk {
  &:before {
    content: $icon-electrotek-talk; 
  }
}
.icon-electrotek-tile {
  &:before {
    content: $icon-electrotek-tile; 
  }
}
.icon-electrotek-tools {
  &:before {
    content: $icon-electrotek-tools; 
  }
}
.icon-electrotek-videocam {
  &:before {
    content: $icon-electrotek-videocam; 
  }
}
.icon-electrotek-videocam-off {
  &:before {
    content: $icon-electrotek-videocam-off; 
  }
}
.icon-electrotek-warning {
  &:before {
    content: $icon-electrotek-warning; 
  }
}
.icon-electrotek-warning-fill {
  &:before {
    content: $icon-electrotek-warning-fill; 
  }
}
.icon-electrotek-weak-bluetooth {
  &:before {
    content: $icon-electrotek-weak-bluetooth; 
  }
}
.icon-electrotek-wifi {
  &:before {
    content: $icon-electrotek-wifi; 
  }
}
.icon-electrotek-wrench {
  &:before {
    content: $icon-electrotek-wrench; 
  }
}
.icon-electrotek-yawn {
  &:before {
    content: $icon-electrotek-yawn; 
  }
}
.icon-electrotek-zoom-in {
  &:before {
    content: $icon-electrotek-zoom-in; 
  }
}
.icon-electrotek-zoom-out {
  &:before {
    content: $icon-electrotek-zoom-out; 
  }
}
.icon-electrotek-battery-0 {
  &:before {
    content: $icon-electrotek-battery-0; 
  }
}
.icon-electrotek-battery-02 {
  &:before {
    content: $icon-electrotek-battery-02; 
  }
}
.icon-electrotek-battery-1 {
  &:before {
    content: $icon-electrotek-battery-1; 
  }
}
.icon-electrotek-battery-12 {
  &:before {
    content: $icon-electrotek-battery-12; 
  }
}
.icon-electrotek-battery-2 {
  &:before {
    content: $icon-electrotek-battery-2; 
  }
}
.icon-electrotek-battery-22 {
  &:before {
    content: $icon-electrotek-battery-22; 
  }
}
.icon-electrotek-battery-3 {
  &:before {
    content: $icon-electrotek-battery-3; 
  }
}
.icon-electrotek-battery-32 {
  &:before {
    content: $icon-electrotek-battery-32; 
  }
}
.icon-electrotek-battery-4 {
  &:before {
    content: $icon-electrotek-battery-4; 
  }
}
.icon-electrotek-battery-42 {
  &:before {
    content: $icon-electrotek-battery-42; 
  }
}
.icon-electrotek-bolt {
  &:before {
    content: $icon-electrotek-bolt; 
  }
}
.icon-electrotek-power-off {
  &:before {
    content: $icon-electrotek-power-off; 
  }
}
.icon-electrotek-star {
  &:before {
    content: $icon-electrotek-star; 
  }
}
.icon-electrotek-star-o {
  &:before {
    content: $icon-electrotek-star-o; 
  }
}
.icon-electrotek-th-list {
  &:before {
    content: $icon-electrotek-th-list; 
  }
}
.icon-electrotek-thumb-press {
  &:before {
    content: $icon-electrotek-thumb-press; 
  }
}
.icon-electrotek-thumbs-up {
  &:before {
    content: $icon-electrotek-thumbs-up; 
  }
}
.icon-electrotek-thumbs-up2 {
  &:before {
    content: $icon-electrotek-thumbs-up2; 
  }
}
.icon-electrotek-user-circle {
  &:before {
    content: $icon-electrotek-user-circle; 
  }
}
.icon-electrotek-user-circle-o {
  &:before {
    content: $icon-electrotek-user-circle-o; 
  }
}
.icon-electrotek-bluetooth {
  &:before {
    content: $icon-electrotek-bluetooth; 
  }
}
.icon-electrotek-bluetooth-disconnected {
  &:before {
    content: $icon-electrotek-bluetooth-disconnected; 
  }
}
.icon-electrotek-flag {
  &:before {
    content: $icon-electrotek-flag; 
  }
}
.icon-electrotek-health {
  &:before {
    content: $icon-electrotek-health; 
  }
}
.icon-electrotek-menu {
  &:before {
    content: $icon-electrotek-menu; 
  }
}
.icon-electrotek-note_add {
  &:before {
    content: $icon-electrotek-note_add; 
  }
}
.icon-electrotek-sd_storage {
  &:before {
    content: $icon-electrotek-sd_storage; 
  }
}
.icon-electrotek-sd_card {
  &:before {
    content: $icon-electrotek-sd_card; 
  }
}
.icon-electrotek-speedometer {
  &:before {
    content: $icon-electrotek-speedometer; 
  }
}
.icon-electrotek-straighten {
  &:before {
    content: $icon-electrotek-straighten; 
  }
}
.icon-electrotek-usb {
  &:before {
    content: $icon-electrotek-usb; 
  }
}
.icon-electrotek-cloud-complete {
  &:before {
    content: $icon-electrotek-cloud-complete; 
  }
}
.icon-electrotek-cloud-disabled {
  &:before {
    content: $icon-electrotek-cloud-disabled; 
  }
}
.icon-electrotek-cloud-offline {
  &:before {
    content: $icon-electrotek-cloud-offline; 
  }
}
.icon-electrotek-cloud-pending {
  &:before {
    content: $icon-electrotek-cloud-pending; 
  }
}
.icon-electrotek-cloud-uploading {
  &:before {
    content: $icon-electrotek-cloud-uploading; 
  }
}
.icon-electrotek-external-event {
  &:before {
    content: $icon-electrotek-external-event; 
  }
}
.icon-electrotek-hardware {
  &:before {
    content: $icon-electrotek-hardware; 
  }
}
.icon-electrotek-low-battery {
  &:before {
    content: $icon-electrotek-low-battery; 
  }
}
.icon-electrotek-ok-battery {
  &:before {
    content: $icon-electrotek-ok-battery; 
  }
}
.icon-electrotek-software-recovery {
  &:before {
    content: $icon-electrotek-software-recovery; 
  }
}
.icon-electrotek-control-faster-backward {
  &:before {
    content: $icon-electrotek-control-faster-backward; 
  }
}
.icon-electrotek-control-faster-forward {
  &:before {
    content: $icon-electrotek-control-faster-forward; 
  }
}
.icon-electrotek-control-fastest-backward {
  &:before {
    content: $icon-electrotek-control-fastest-backward; 
  }
}
.icon-electrotek-control-fastest-forward {
  &:before {
    content: $icon-electrotek-control-fastest-forward; 
  }
}
.icon-electrotek-retrieve-data {
  &:before {
    content: $icon-electrotek-retrieve-data; 
  }
}
.icon-electrotek-sync-data {
  &:before {
    content: $icon-electrotek-sync-data; 
  }
}
.icon-electrotek-wait-and-upload {
  &:before {
    content: $icon-electrotek-wait-and-upload; 
  }
}
.icon-electrotek-lungs {
  &:before {
    content: $icon-electrotek-lungs; 
  }
}
.icon-electrotek-lungs-fill {
  &:before {
    content: $icon-electrotek-lungs-fill; 
  }
}
.icon-electrotek-thermometer-three-quarters {
  &:before {
    content: $icon-electrotek-thermometer-three-quarters; 
  }
}
.icon-electrotek-wind {
  &:before {
    content: $icon-electrotek-wind; 
  }
}
.icon-electrotek-wind2 {
  &:before {
    content: $icon-electrotek-wind2; 
  }
}

