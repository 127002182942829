.montages {
    .header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);

        h3 {
            margin-bottom: 10px;
        }

        i {
            font-size: 30px;
        }

        span {
            margin-left: 10px;
            position: relative;
            top: -4px;
        }
    }
}
