@import "../../scss/mmt/mobilemedtek_colors";

.patient-documents {
    position: relative;

    .drop-message {
        margin: 10px 0 10px;
        padding: 10px 20px;
        border: 1px dashed rgba(255, 255, 255, 0.25);
        display: inline-block;
    }

    .upload-icon-label {
        margin: 0;
        position: relative;
        top: 18px;
        font-size: 18px;

        i {
            cursor: pointer;
            color: $electro-tek-blue;

            &:hover {
                color: lighten($electro-tek-blue, 5%);
            }
        }
    }

    .progress {
        margin-bottom: 16px;
        margin-top: 6px;
        height: 10px;
        border-radius: 5px;
        overflow: hidden;

        &.no-transition {
            .progress-bar {
                transition: none !important;
            }
        }
    }

    .action-icons {
        text-align: right;
        white-space: nowrap;

        .icon {
            cursor: pointer;
            margin-left: 15px;
            color: $rendr-wave-3;

            &:hover {
                color: #fff;
            }

            &:first-child {
                margin: 0;
            }
        }
    }

    .dropper {
        background: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        user-select: none;

        &.dropping {
            display: block;
            background: rgba(0, 0, 0, 0.75);
        }

        * {
            left: 50%;
            margin-top: 50px;
            color: #fff;
            user-select: none;
            pointer-events: none;
        }
    }

    .document-table {
        th i {
            margin-left: 6px;
        }
    }
}