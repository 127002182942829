@import "../../../../scss/z-index";
@import "../../../../scss/mmt/mobilemedtek_colors";

.scrubber-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    z-index: $scrubber-z-index;

    .scrubber {
        background: #777;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;

        .section {
            height: 100%;
            position: absolute;

            &.epoch {
                background: #{$rendr-wave-2}99;
                min-width: 2px;
            }

            &.loaded {
                background: #ccc;
            }

            &.requested {
                background: #999;
            }

            &.time-display {
                $background-color: rgba(0, 0, 0, 0.5);
                $arrow-length: 35px;
                $arrow-width: 10px;
                $arrow-height: 5px;
                background: $background-color;
                color: #fff;
                font-size: 14px;

                .time {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                }

                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 0;
                }

                &::before {
                    width: 0;
                    border-top: $arrow-height solid transparent;
                    border-bottom: $arrow-height solid transparent;
                }

                &::after {
                    width: $arrow-length - $arrow-width;
                    border-top: 1px solid $background-color;
                }

                &.right {
                    transform: translateX($arrow-length);
                    
                    &::before {
                        left: -$arrow-length;
                        border-right: $arrow-width solid $background-color;
                    }

                    &::after {
                        left: -$arrow-length + $arrow-width;
                        border-right: $arrow-width solid $background-color;
                    }
                }

                &.left {
                    transform: translateX(-$arrow-length);
                    
                    &::before {
                        right: -$arrow-length;
                        border-left: $arrow-width solid $background-color;
                    }

                    &::after {
                        right: -$arrow-length + $arrow-width;
                        border-left: $arrow-width solid $background-color;
                    }
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }

    .follow-live-data {
        position: absolute;
        right: 0;
        font-size: 1.5rem;
        height: 30px;
        background-color: rgba($electro-tek-white, 0.9);
        color: $rendr-wave-2;
        cursor: pointer;
        text-align: center;
        padding-top: 3px;

        &:hover {
            background-color: $electro-tek-white;
            color: $rendr-wave-3;
        }
    }

    .follow-live-data.disabled {
        color: $electro-tek-gray;
        background-color: rgba($electro-tek-white, 0.85);

        &:hover {
            cursor: not-allowed;
        }
    }
}
