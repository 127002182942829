@import "../../../scss/mmt/mobilemedtek_colors";

.change-patient-modal {
    .transition {
      font-size: 3.5em;
      color: $color-green-50;
    }

    .add-patient-card {
      cursor: pointer;

      &:hover {
        background-color: #f0f3f5;
      }
    }

    .add-patient-icon {
      font-size: 5em;
    }
}
