.stepper {
    position: relative;
    margin-bottom: 60px;
    margin-top: 20px;

    .progress {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: 12px;
    }

    .step-icon {
        position: absolute;
        border-radius: 50%;
        border: 5px solid rgba(255, 255, 255, 0.2);
        transition: border ease-in-out 0.5s;

        &.bg-success {
            border: 5px solid transparent;
        }

        &.bg-gray {
            background: #515b65;
        }

        i {
            font-size: 22px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .step-name {
        position: absolute;
        top: 100%;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin-top: 3px;
        font-size: 16px;
    }
}
