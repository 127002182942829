.loading-spinner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .loading-spinner-txt {
        color: #fff;
    }
}
