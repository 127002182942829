@import "../../../../scss/z-index";

.legend {
    position: absolute;
    z-index: $legend-z-index;
    pointer-events: none;
    user-select: none;

    .lines {
        $tick-size: 3px;

        cursor: grab;
        position: absolute;
        pointer-events: all;
        display: inline-block;
        border-left: 1px solid;
        border-bottom: 1px solid;
        transition: width 1s ease;
        height: 1cm;

        .sensitivity-tick,
        .scale-tick {
            display: block;
            position: absolute;
        }

        .sensitivity-tick {
            border-top: 1px solid;
            width: $tick-size;
        }

        .scale-tick {
            height: $tick-size;
            border-right: 1px solid;
            right: 0;
            bottom: 0;
        }
    }

    &.grabbing {
        .lines {
            cursor: grabbing;
        }
    }

    .sensitivity,
    .scale {
        font-size: 14px;
    }

    .sensitivity {
        position: absolute;
        right: calc(100% + 5px);
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
    }

    .scale {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        top: 100%;
        white-space: nowrap;
    }
}
