// Here you can add other styles
@import 'z-index.scss';
@import 'mmt/studyviewer/ElectroTek';
@import 'mmt/electrotek_icons/style.scss';
// @import 'mmt/fontawesome_extra';

@media (min-width: 576px) {
    .form-group label {
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
    }
}

body {
    overscroll-behavior: none;
}

.form-control[readonly] {
    background: transparent;
    color: white;
    border: none;
    box-shadow: none;

    &.select {
        // remove select arrows icons
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }

    &::placeholder {
        color: transparent;
    }
}

.row-action-icons {
    white-space: nowrap;
    color: $rendr-wave-4;
    .fa {
        cursor: pointer;
    }
    float: right;
}

.btn,
.btn:focus,
.no-outline:focus {
    outline: 0 !important;
}

input[type='search']::-webkit-search-cancel-button {
    display: none;
}

input[type='password']::-ms-reveal {
    display: none;
}
